import React from 'react';
import styled from 'styled-components';
import { FaRegClock, FaCertificate, FaStar, FaPlayCircle } from 'react-icons/fa';

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Breadcrumb = styled.div`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  flex: 3;
  margin-right: 20px;
`;

const RightColumn = styled.div`
  flex: 2;
`;

const CourseImage = styled.div`
  background: url('https://path-to-your-image.jpg') center center/cover no-repeat;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayIcon = styled(FaPlayCircle)`
  color: white;
  font-size: 3rem;
`;

const TitleSection = styled.div`
  margin-top: 20px;
`;

const CourseTitle = styled.h1`
  font-size: 2rem;
  margin: 0;
`;

const InstructorInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #888;

  img {
    border-radius: 50%;
    margin-right: 10px;
  }
`;

const PriceTag = styled.div`
  font-size: 1.5rem;
  color: #d9534f;
  margin-top: 20px;
`;

const AddToCartButton = styled.button`
  background-color: #d9534f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1rem;

  &:hover {
    background-color: #c9302c;
  }
`;

const CourseInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #555;

  & > div {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 5px;
  }
`;

const ContentSection = styled.div`
  margin-top: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const CourseContentList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
  }
`;

const LeftText = styled.span`
  color: ${({ active }) => (active ? '#d9534f' : '#000')};
`;

const RightText = styled.span`
  color: ${({ active }) => (active ? '#d9534f' : '#888')};
`;

const CourseDetails = styled.div`
  margin-top: 40px;
`;

const DetailItem = styled.div`
  margin-bottom: 20px;
`;

const TabMenu = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-top: 20px;
`;

const TabItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.8rem;  // Diminution de la taille de la police
  color: ${({ active }) => (active ? '#d9534f' : '#666')};
  border-bottom: ${({ active }) => (active ? '2px solid #d9534f' : 'none')};

  &:hover {
    color: #d9534f;
  }
`;

const DetailSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #555;
  margin-top: 20px;
`;

const DetailSummaryItem = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    color: #f39c12;
  }
`;

const CourseDetail = () => {
  return (
    <MainContainer>
      <Breadcrumb>Home &gt; Courses &gt; Course Detail</Breadcrumb>
      <ContentWrapper>
        <LeftColumn>
          <CourseImage>
            <PlayIcon />
          </CourseImage>
          <TabMenu>
            <TabItem active>À propos</TabItem>
            <TabItem>Curriculum</TabItem>
            <TabItem>Instructeur</TabItem>
            <TabItem>FAQ</TabItem>
            <TabItem>Ressources</TabItem>
            <TabItem>Du même auteur</TabItem>
          </TabMenu>
          <CourseDetails>
            <DetailItem>
              <h2>EduCavo Course Details</h2>
              <p>
                Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus,
                eleifend euismod pellentesque vel, sagittis vel justo. In libero urna, venenatis sit
                amet ornare non, suscipit nec risus. Sed consequat justo non mauris pretium at tempor
                justo sodales. Quisque tincidunt laoreet malesuada. Cum sociis natoque penatibus.
              </p>
              <p>
                Eleifend euismod pellentesque vel Phasellus enim magna, varius et commodo ut,
                ultricies vitae velit. Ut nulla tellus, sagittis vel justo. In libero urna, venenatis
                sit amet ornare non, suscipit nec risus. Sed consequat justo non mauris pretium at
                tempor justo sodales. Quisque tincidunt laoreet malesuada. Cum sociis natoque
                penatibus.
              </p>
              <DetailSummary>
                <DetailSummaryItem>
                  <span>23,564 Total Students</span>
                </DetailSummaryItem>
                <DetailSummaryItem>
                  <span>4.5</span>
                  <span>⭐</span>
                  <span>(1254 Rating)</span>
                </DetailSummaryItem>
                <DetailSummaryItem>
                  <span>256 Reviews</span>
                </DetailSummaryItem>
              </DetailSummary>
            </DetailItem>
            <DetailItem>
              <h2>What you'll learn?</h2>
              <ul>
                <li>Phasellus enim magna, varius et commodo ut.</li>
                <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo.</li>
                <li>Phasellus enim magna, varius et commodo ut.</li>
                <li>Phasellus enim magna, varius et commodo ut.</li>
                <li>Sed consequat justo non mauris pretium at tempor justo.</li>
                <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                <li>Phasellus enim magna, varius et commodo ut</li>
              </ul>
            </DetailItem>
            <DetailItem>
              <h2>Requirements</h2>
              <ul>
                <li>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</li>
                <li>Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                <li>Phasellus enim magna, varius et commodo ut.</li>
                <li>Varius et commodo ut, ultricies vitae velit. Ut nulla tellus.</li>
                <li>Phasellus enim magna, varius et commodo ut.</li>
              </ul>
            </DetailItem>
          </CourseDetails>
        </LeftColumn>
        <RightColumn>
          <TitleSection>
            <CourseTitle>Spicy Quince And Cranberry Chutney</CourseTitle>
            <InstructorInfo>
              <img src="https://via.placeholder.com/40" alt="Instructor" />
              <span>Un cours de <strong>Gustave ALI-COUDI</strong></span>
            </InstructorInfo>
            <PriceTag>45,99 €</PriceTag>
            <AddToCartButton onClick={() => window.location.href='/shop/cart'}>Ajouter au panier</AddToCartButton>


            <CourseInfo>
              <div><FaRegClock /> 2h30m</div>
              <div><FaCertificate /> Certificat</div>
              <div><FaStar /> 4.5</div>
            </CourseInfo>
          </TitleSection>
          <ContentSection>
            <SectionTitle>Contenu</SectionTitle>
            <CourseContentList>
              <li>
                <LeftText active>01 - ultricies vitae velit. Ut nulla tellus</LeftText>
                <RightText active>3m12s</RightText>
              </li>
              <li>
                <LeftText>02 - ultricies vitae velit.</LeftText>
                <RightText>3m12s</RightText>
              </li>
              <li>
                <LeftText>03 - ultricies vitae velit.</LeftText>
                <RightText>3m12s</RightText>
              </li>
              <li>
                <LeftText>03 - ultricies vitae velit.</LeftText>
                <RightText>3m12s</RightText>
              </li>
              <li>
                <LeftText>03 - ultricies vitae velit.</LeftText>
                <RightText>3m12s</RightText>
              </li>
              <li>
                <LeftText>03 - ultricies vitae velit.</LeftText>
                <RightText>3m12s</RightText>
              </li>
            </CourseContentList>
          </ContentSection>
        </RightColumn>
      </ContentWrapper>
    </MainContainer>
  );
};

export default CourseDetail;
