import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { FaVideo } from 'react-icons/fa';

const DropzoneContainer = styled.div`
  border: 2px dashed #ccc;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 100px;
  background: #f5f5f5;
`;

const DropzoneContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ButtonIcon = styled(FaVideo)`
  margin-bottom: 10px;
  font-size: 24px;
`;

const DropzoneText = styled.p`
  font-size: 16px;
  color: #666;
  margin: 0;
`;

const Dropzone = ({ onDrop, title }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'video/*',
    onDrop,
  });

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      <DropzoneContent>
        <ButtonIcon />
        {title ? (
          <DropzoneText>{title}</DropzoneText>
        ) : (
          <DropzoneText>Ajouter une vidéo</DropzoneText>
        )}
      </DropzoneContent>
    </DropzoneContainer>
  );
};

export default Dropzone;
