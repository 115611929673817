import React, { useState, useEffect, useCallback } from 'react';
import { CiUser, CiLock } from "react-icons/ci";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaEye } from "react-icons/fa";
import { Link, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { login } from '../../../services/auth';
import NotificationService from '../../../services/notification';
import { Field, Input, Label, Button, Checkbox } from '@headlessui/react';
import axios from "axios";
import { useGoogleLogin } from '@react-oauth/google';
import { Switch } from '@headlessui/react';
import ReCAPTCHA from "react-google-recaptcha";
import FacebookAuth from 'react-facebook-auth';

const MyFacebookButton = ({ onClick }) => (
    <Button onClick={onClick} className="flex justify-center items-center rounded-full bg-gray-300 py-2 px-4 text-sm text-black hover:bg-gray-400 active:bg-gray-500 w-full">
        <FaFacebook size={20} color={"blue"} className="mr-2" /> <span>FACEBOOK</span>
    </Button>

);

const authenticate = (response) => {
    console.log(response);
    // Api call to server so we can validate the token
};
function LoginForm() {
    const [enabled, setEnabled] = useState(false)
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const navigate = useNavigate();

    const { token } = useParams();

    const [data, setData] = useState({});
    const [picture, setPicture] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (captchaValue) {
            const data = {
                email: email,
                password: password,
                login_by: 'manual',
                confirm_token: token
            };

            await login(data).then(response => {
                setLoading(false);
                if (response.data.success) {

                    NotificationService.success("Connexion réussie");
                    localStorage.setItem('token', response.data.data.token);
                    navigate('/dashboard');
                } else {

                    NotificationService.error(response.data.error);
                }
            }).catch(error => {
                setLoading(false);
                NotificationService.error("Une erreur s'est produite. Veuillez réessayer");
            });
        } else {
            NotificationService.error("Veuillez compléter le CAPTCHA");
        }


    }
    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => res.data);

            const data = {
                email: userInfo.email,
                nom: userInfo.family_name,
                prenom: userInfo.given_name,
                password: userInfo.sub,
                login_by: 'google',
                picture: userInfo.picture
            };

            await login(data).then(response => {
                setLoading(false);
                if (response.data.success) {
                    NotificationService.success("Connexion réussie");
                    localStorage.setItem('token', response.data.data.token);
                    navigate('/dashboard');
                } else {
                    NotificationService.error("Email ou mot de passe incorrect");
                }
            })
        },
    });

    // useEffect(() => {
    //     window.fbAsyncInit = function () {
    //         window.FB.init({
    //             appId: '825840065661279',
    //             cookie: true,
    //             xfbml: true,
    //             version: 'v10.0',
    //             auth_type: 'reauthorize'
    //         });
    //         window.FB.AppEvents.logPageView();
    //     };
    // }, []);


    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const captcha_Key = process.env.GOOGLE_CAPTCHA

    // const handleFBLogin = () => {
    //     window.FB.login(checkLoginState, { scope: 'public_profile,email' });
    // };

    return (
        <div className="max-w-[600px] mx-auto flex flex-col items-center px-8">
            <Field className="w-full mb-10">
                <Label>Email</Label>
                <div className='relative w-full mt-2'>
                    <CiUser size={25} className='absolute top-1/2 transform -translate-y-1/2 left-4' />
                    <Input
                        style={{ paddingLeft: '3rem' }}
                        className="border focus:shadow border-gray-500 py-3 pr-4 rounded-full w-full"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        type="email"
                        placeholder="contact@akambiconsulting.com"
                    />
                </div>
            </Field>

            <Field className="w-full mb-10">
                <Label>Mot de passe *</Label>
                <div className='relative w-full mt-2'>
                    <CiLock size={25} className='absolute top-1/2 transform -translate-y-1/2 left-4' />
                    <Input
                        style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                        className="border focus:shadow border-gray-500 py-3 rounded-full w-full"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder=""
                    />
                    <Button
                        type="button"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <FaEye size={25} />
                    </Button>
                </div>
            </Field>

            <div className="flex w-full justify-between mb-4">
                <div>
                    <Field className="flex items-center gap-2">
                        <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className={`${enabled ? 'bg-blue-500' : 'bg-gray-200'}
                                relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                            <span className="sr-only">Se souvenir de moi</span>
                            <span
                                className={`${enabled ? 'translate-x-6' : 'translate-x-1'}
                                    inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </Switch>
                        <Label>Se souvenir de moi</Label>
                    </Field>
                </div>

                <div>
                    <Link to="/resset-mail" className="text-red-500">Mot de passe oublié</Link>
                </div>
            </div>
            <div className="flex justify-start py-4 w-full">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_PROD}
                    onChange={handleCaptchaChange}
                />,
            </div>
            <div className="w-full flex flex-1">
                <Button
                    className="rounded-full bg-red-600 py-3 px-4 text-sm text-white hover:bg-red-500 active:bg-red-700 w-full"
                    onClick={handleSubmit}
                >
                    Connexion
                </Button>
            </div>



            <div className="flex flex-col items-center py-3">
                <p className="bg-red-red">Ou s'inscrire avec</p>
            </div>

            <div className="flex gap-4 mb-4 items-center">
                <div>
                    <FacebookAuth
                        appId="503124842154525"
                        callback={authenticate}
                        component={MyFacebookButton}
                    />
                    {/* <Button className="flex justify-center items-center rounded-full bg-gray-300 py-2 px-4 text-sm text-black hover:bg-gray-400 active:bg-gray-500 w-full">
                        <FaFacebook size={20} color={"blue"} className="mr-2" /> <span>FACEBOOK</span>
                    </Button> */}

                </div>
                <div>
                    <Button
                        className="flex justify-center items-center rounded-full bg-gray-300 py-2 px-4 text-sm text-black hover:bg-gray-400 active:bg-gray-500 w-full"
                        onClick={googleLogin}
                    >
                        <FcGoogle size={20} className="mr-2" /> <span>GOOGLE</span>
                    </Button>
                </div>
            </div>


        </div>
    );
}

export default LoginForm;
