

const SingleTestimonialThree = (props) => {
    const { itemClass, authorImage, Title, Designation, Description } = props;
    return (

        <div className={itemClass ? itemClass : 'testi-item'}>
        <div className="d-flex align-items-center">
            <img style={{ width: "48px" , height: "48px", margin: "15px"}}
                src={authorImage}
                alt={Title}
            />
            <div className="user-info">
                <h4 className="name">{Title ? Title : 'Saiko Najran'}</h4>
                <span className="designation">{Designation ? Designation : 'Student'}</span>
            </div>
        </div>
        <div className="desc">{Description ? Description : 'The charms of pleasure of the moment so blinded by desire that they cannot foresee the pain and trouble that are bound ensue and equal blame belongs to those who fail in their duty.'}</div>
    </div>
    )
}

export default SingleTestimonialThree