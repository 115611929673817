import React from 'react';
import { Link } from 'react-router-dom';

const CategoriesSingleThree = (props) => {
    const { categoriesClass, iconImg, title, catLink, courseQuantity } = props;

    return (
        <div className={categoriesClass ? categoriesClass : 'subject-wrap bgc1'}>
            <img
                src={iconImg}
                alt={title}
            />
            <div className="content-part">
                <h4 className="title">
                    <Link to={catLink ? catLink : '/course-categories'}>
                        {title ? title : 'General Education'}
                    </Link>
                </h4>
               
                <span className="course-qnty"><a style={{color : "#000000", fontSize:"14px", width:"56px", height:"10px"}} className="service-btn" href="/course-categories">{courseQuantity ? courseQuantity : '05'} cours &nbsp; <i className="fa fa-arrow-right"></i></a></span>
 
                    
            </div>
        </div>
    )
}

export default CategoriesSingleThree