import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Définir les composants stylisés
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  @media (max-width: 768px) {
    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 10px;
    }

    td {
      display: block;
      text-align: right;
      padding-left: 50%;
      position: relative;

      &:before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        font-weight: bold;
        text-align: left;
      }
    }
  }
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left;
  cursor: pointer;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;

  @media (max-width: 768px) {
    padding-left: 50%;
    position: relative;

    &:before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 10px;
      font-weight: bold;
      text-align: left;
    }
  }
`;

const Pagination = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

const DangerButton = styled(Button)`
  background-color: #f44336;

  &:hover {
    background-color: #d32f2f;
  }
`;

const FilterInput = styled.input`
  margin: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
`;

const FormContainer = styled.div`
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const StyledField = styled(Field)`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: #f44336;
  margin: 5px 0;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
`;

const initialValues = { Nom: '', Âge: '', Profession: '' };

const validationSchema = Yup.object({
  Nom: Yup.string().required('Requis'),
  Âge: Yup.number().required('Requis').positive('Doit être positif').integer('Doit être un entier'),
  Profession: Yup.string().required('Requis'),
});

const TableBo = ({ columns, data, setData, rowsPerPage = 5 }) => {
  const [sortedData, setSortedData] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState('');
  const [editingRow, setEditingRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewingRow, setViewingRow] = useState(null);

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    const sortedArray = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setSortedData(sortedArray);
    setSortConfig({ key, direction });
  };

  const handleAddOrEdit = (values) => {
    if (editingRow !== null) {
      const updatedData = sortedData.map((row, index) =>
        index === editingRow ? values : row
      );
      setData(updatedData);
      setSortedData(updatedData);
    } else {
      const updatedData = [...sortedData, values];
      setData(updatedData);
      setSortedData(updatedData);
    }
    setEditingRow(null);
  };

  const handleDelete = (index) => {
    const updatedData = sortedData.filter((_, rowIndex) => rowIndex !== index);
    setData(updatedData);
    setSortedData(updatedData);
  };

  const handleToggleActive = (index) => {
    const updatedData = sortedData.map((row, rowIndex) => 
      rowIndex === index ? { ...row, Actif: !row.Actif } : row
    );
    setData(updatedData);
    setSortedData(updatedData);
  };

  const handleView = (index) => {
    setViewingRow(sortedData[index]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setViewingRow(null);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const filteredData = sortedData.filter(row =>
    columns.some(col => row[col].toString().toLowerCase().includes(filterText.toLowerCase()))
  );
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <h1>Hello Admin</h1>
      <FilterInput 
        type="text" 
        placeholder="Recherche..." 
        value={filterText} 
        onChange={(e) => setFilterText(e.target.value)} 
      />
      <Table>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <Th key={index} onClick={() => sortData(col)}>{col}</Th>
            ))}
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col, colIndex) => (
                <Td key={colIndex} data-label={col}>{row[col]}</Td>
              ))}
              <Td>
                <Button onClick={() => setEditingRow(rowIndex)}>Modifier</Button>
                <DangerButton onClick={() => handleDelete(rowIndex)}>Supprimer</DangerButton>
                <Button onClick={() => handleToggleActive(rowIndex)}>
                  {row.Actif ? 'Désactiver' : 'Activer'}
                </Button>
                <Button onClick={() => handleView(rowIndex)}>Afficher</Button>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </Button>
        ))}
      </Pagination>
      <FormContainer>
        <h2>{editingRow !== null ? 'Modifier l\'élément' : 'Ajouter un élément'}</h2>
        <Formik
          initialValues={editingRow !== null ? sortedData[editingRow] : initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAddOrEdit}
        >
          {({ errors, touched }) => (
            <StyledForm>
              {columns.map((col) => (
                <div key={col}>
                  <label>{col}</label>
                  <StyledField name={col} />
                  <StyledErrorMessage component="div" name={col} />
                </div>
              ))}
              <Button type="submit">{editingRow !== null ? 'Modifier' : 'Ajouter'}</Button>
            </StyledForm>
          )}
        </Formik>
      </FormContainer>
      {showModal && (
        <ModalBackground>
          <ModalContent>
            <h2>Détails</h2>
            {columns.map((col, index) => (
              <p key={index}><strong>{col}:</strong> {viewingRow[col]}</p>
            ))}
            <Button onClick={closeModal}>Fermer</Button>
          </ModalContent>
        </ModalBackground>
      )}
    </>
  );
};

export default TableBo;