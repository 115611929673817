import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaChevronRight, FaChevronLeft, FaCheck } from 'react-icons/fa';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import { getCategory } from '../../../services/categorieCourses';
import { createCourse } from 'services/course';
import NotificationService from 'services/notification';
import { useNavigate } from 'react-router-dom';


const CreateCourseContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Sidebar = styled.div`
  flex: 0 0 260px;
  padding: 15px;
  background-color: #fff;
  border-right: 1px solid #ddd;
  overflow-y: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MainContent = styled.div`
  flex: 1;
  padding: 68px;
  overflow-y: auto;
  background-color: #fff;
  width: 100%;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const StepList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
`;

const StepItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${({ active }) => (active ? '#dc3545' : '#333')};
  position: relative;
  padding-left: 40px;

  .step-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-color: ${({ completed, active }) => (completed || active ? '#dc3545' : '#fff')};
    border: 2px solid ${({ completed, active }) => (completed || active ? '#dc3545' : '#ddd')};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .step-text {
    margin-left: 40px;

    .step-title {
      font-weight: bold;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 20px;
  margin-bottom: 60px;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Button = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:hover {
    background-color: #bb2d3b;
  }

  .icon {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
  }

  @media (max-width: 576px) {
    font-size: 12px;
    padding: 6px 12px;
    justify-content: center;
    .icon {
      margin-left: 5px;
    }
  }
`;

const CreateCourse = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    typeCourse: '',
    summary: {
      cartegory_id: '',
      modules: [
        { name: '', chapters: [{ title: '', contenu: '', video: '', file: undefined }] }
      ]
    },
    description: { courseTitle: '', description: '', keywords: '' },
    price: { currency: '', amount: '', duration: '' },
    cover: {},
    qcm: [],
  });

  const loadCategories = async () => {
    try {
      const response = await getCategory();
      setCategories(response.data.data || []);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const getSteps = () => {
    if (formData.typeCourse === 'visio-conference') {
      return [
        { step: 1, name: 'Type de cours' },
        { step: 2, name: 'Description' },
        { step: 3, name: 'Prix' },
        { step: 4, name: 'Cover' },
      ];
    } else {
      return [
        { step: 1, name: 'Type de cours' },
        { step: 2, name: 'Description' },
        { step: 3, name: 'Sommaire' },
        { step: 4, name: 'Prix' },
        { step: 5, name: 'Cover' },
        { step: 6, name: 'QCM' },
      ];
    }
  };

  const steps = getSteps();

  const nextStep = () => {
    setCurrentStep((prev) => {
      const currentIndex = steps.findIndex((s) => s.step === prev);
      if (currentIndex < steps.length - 1) {
        return steps[currentIndex + 1].step;
      }
      return prev;
    });
  };

  const prevStep = () => {
    setCurrentStep((prev) => {
      const currentIndex = steps.findIndex((s) => s.step === prev);
      if (currentIndex > 0) {
        return steps[currentIndex - 1].step;
      }
      return prev;
    });
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepOne data={formData} setData={setFormData} />;
      case 2:
        return <StepThree data={formData} setData={setFormData} />;
      case 3:
        return formData.typeCourse === 'visio-conference' ? (
          <StepFour data={formData} setData={setFormData} courseType={formData.typeCourse} />
        ) : (
          <StepTwo data={formData} setData={setFormData} categoriesCourse={categories} />
        );
      case 4:
        return formData.typeCourse === 'visio-conference' ? (
          <StepFive data={formData} setData={setFormData} />
        ) : (
          <StepFour data={formData} setData={setFormData} courseType={formData.typeCourse} />
        );
      case 5:
        return formData.typeCourse !== 'visio-conference' ? (
          <StepFive data={formData} setData={setFormData} />
        ) : null;
      case 6:
        return formData.typeCourse !== 'visio-conference' ? (
          <StepSix data={formData} setData={setFormData} />
        ) : null;
      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    try {
      const data = new FormData();
      data.append('data', JSON.stringify(formData));
      const response = await createCourse(data);
      if (response.data.success) {
        NotificationService.success('Cours ajouté avec succès');
        navigate('/dashboard/mycourses');
      } else {
        NotificationService.error("Erreur lors de l'ajout du cours");
      }
    } catch (error) {
      NotificationService.error("Erreur lors de l'ajout du cours");
    }
  };

  return (
    <CreateCourseContainer>
      <ContentWrapper>
        <Sidebar>
          <SectionTitle>Création d'un cours</SectionTitle>
          <StepList>
            {steps.map(({ step, name }, index) => (
              <StepItem
                key={step}
                active={currentStep === step}
                completed={currentStep > step}
              >
                <div className="step-icon">
                  {currentStep > step ? (
                    <FaCheck />
                  ) : (
                    <div>{index + 1}</div>
                  )}
                </div>
                <div className="step-text">
                  <div>Étape {index + 1}</div>
                  <div className="step-title">{name}</div>
                </div>
              </StepItem>
            ))}
          </StepList>
        </Sidebar>
        <MainContent>
          {renderStep()}
          <ButtonGroup>
            {currentStep > 1 && (
              <Button onClick={prevStep}>
                <FaChevronLeft className="icon" />
                Précédent
              </Button>
            )}
            {currentStep < steps.length ? (
              <Button onClick={nextStep}>
                Suivant
                <FaChevronRight className="icon" />
              </Button>
            ) : (
              <Button onClick={handleSubmit}>Valider</Button>
            )}
          </ButtonGroup>
        </MainContent>
      </ContentWrapper>
    </CreateCourseContainer>
  );
};

export default CreateCourse;
