import axios  from "axios";

import { API_BASE_URL, headers } from ".";
export const token = localStorage.getItem('token');

export const getCategory = () => {
    return axios.get(`${API_BASE_URL}/instructor/categories`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const saveCategorieCourses = (data) => {
    return axios.post(`${API_BASE_URL}/add/category`,data, {
        headers: {}
    });
};

export const updateCategorieCourses = (data) => {
    return axios.post(`${API_BASE_URL}/admin/courses`,data, {
        headers: {}
    });
}

export const deleteCategorieCourses = (id) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, id);
}

export const getCategorieCourses = (id) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, id);
}