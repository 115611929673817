import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SectionTitle from '../../components/Common/SectionTitle';
import CourseSingle from '../../components/Courses/CourseSingle';
import { getLastCourses } from '../../services/course';
import image1 from '../../assets/img/courses/main-home/1.jpg';

const CoursesContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 100px;
`;

const CoursesContent = styled.div`
    max-width: 75%;
    margin: 0 auto;
`;

const CoursesRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CoursesButton = styled(Link)`
    background-color: #FFFFFF;
    border: 1.5px solid #9C1623;
    border-radius: 37px;
    width: 205px;
    height: 36px;
    padding: 8px 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 145%;
    color: #ab1927;
    text-align: center;
`;

const Courses = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await getLastCourses();
                //console.log('API response:', response);

                if (response && Array.isArray(response.data)) {
                    setCourses(response.data);
                } else {
                    //console.error('Courses data is not an array:', response.data);
                }
            } catch (error) {
                //console.error('Failed to fetch courses:', error);
            }
        };

        fetchCourses();
    }, []);

    return (
        <CoursesContainer className="rs-popular-courses main-home event-bg pt-100 pb-100 md-pt-70 md-pb-70">
            <CoursesContent className="container">
                <CoursesRow className="row y-middle mb-50 md-mb-30">
                    <div className="col-md-6 sm-mb-30">
                        <SectionTitle
                            sectionClass="sec-title"
                            subtitleClass="sub-title primary"
                            subtitle={<div>COURS</div>}
                            titleClass="title mb-0"
                            title="Cours populaires"
                            descClass="desc big"
                            description={<div style={{ fontFamily: "'MetropolisMedium', sans-serif", fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "145%" }}>Prêt à commencer votre voyage éducatif ?<br /> Explorez nos cours dès maintenant !</div>}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="btn-part text-right sm-text-left">
                            <CoursesButton to="/course">
                                Voir tous les cours
                            </CoursesButton>
                        </div>
                    </div>
                </CoursesRow>
                <div className="row">
                    {Array.isArray(courses) && courses.length > 0 ? (
                        courses.map(course => (
                            <div key={course.id} className="col-lg-3 col-md-6 mb-30">
                                <CourseSingle
                                    itemClass="courses-item"
                                    image={image1}
                                    title={course.title}
                                    pricing={course.price}
                                    devise={course.devise}
                                    studentQuantity={course.student_quantity}
                                    lessonsQuantity={course.lessons_quantity}
                                    courseLink={course.course_link}
                                    author={course.author}
                                    ratings={course.ratings}
                                />
                            </div>
                        ))
                    ) : (
                        <div>No courses available</div>
                    )}
                </div>
            </CoursesContent>
        </CoursesContainer>
    );
}

export default Courses;
