import React, { useState } from 'react';
import TableBo from 'components/utilities/TableBo';

const TestAdmin = () => {
  const columns = ['Nom', 'Âge', 'Profession'];
  const [data, setData] = useState([
    { Nom: 'John Doe', Âge: 28, Profession: 'Développeur', Actif: true },
    { Nom: 'Jane Smith', Âge: 34, Profession: 'Designer', Actif: true },
    { Nom: 'Sam Green', Âge: 45, Profession: 'Manager', Actif: false }
  ]);

  return (
    <div>
      <TableBo columns={columns} data={data} setData={setData} />
    </div>
  );
}

export default TestAdmin;
