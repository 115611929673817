import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import elearning from 'assets/img/courses/elearning.png';
import visio from 'assets/img/courses/visio.png';

const StepContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px 5px; /* Réduire les marges latérales */
  }

  @media (max-width: 576px) {
    padding: 5px 2px; /* Réduire encore plus les marges latérales pour les très petits écrans */
  }
`;

const StepHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  //text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

const CourseTypeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const CourseTypeCard = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: border-color 0.3s;

  &:hover {
    border-color: #333;
  }

  &.selected {
    border-color: red;
    background-color: #ffe6e6;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 576px) {
    padding: 10px;
  }
`;

const CourseImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
`;

const StepOne = ({ data, setData }) => {
  const [selectedType, setSelectedType] = useState(data.typeCourse || null);

  useEffect(() => {
    setSelectedType(data.typeCourse);
  }, [data.typeCourse]);

  const handleSelectType = (typeCourse) => {
    setSelectedType(typeCourse);
    setData({ ...data, typeCourse });
    localStorage.setItem('course', JSON.stringify({ ...data, typeCourse }));
  };

  return (
    <StepContainer>
      <StepHeader>Type de cours</StepHeader>
      <CourseTypeGrid>
        <CourseTypeCard
          className={selectedType === 'e-learning' ? 'selected' : ''}
          onClick={() => handleSelectType('e-learning')}
        >
          <CourseImage src={elearning} alt="E-learning" />
          <h3>E-learning</h3>
          <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</p>
        </CourseTypeCard>
        <CourseTypeCard
          className={selectedType === 'visio-conference' ? 'selected' : ''}
          onClick={() => handleSelectType('visio-conference')}
        >
          <CourseImage src={visio} alt="Visio-conférence" />
          <h3>Visio-conférence</h3>
          <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</p>
        </CourseTypeCard>
        <CourseTypeCard
          className={selectedType === 'presentiel' ? 'selected' : ''}
          onClick={() => handleSelectType('presentiel')}
        >
          <CourseImage src={visio} alt="Présentiel" />
          <h3>Présentiel</h3>
          <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</p>
        </CourseTypeCard>
      </CourseTypeGrid>
    </StepContainer>
  );
};

export default StepOne;
