import React from 'react';
import { Link } from 'react-router-dom';

import bgImg from '../../assets/img/bg/home1.jpg';

const bgStyle = {
    backgroundImage:`url(${bgImg})`
}

const BannerDefault = () => {
    return (
        <React.Fragment>
            {/* <!-- banner section start --> */}
            <div id="rs-banner" className="rs-banner style1" style={bgStyle}>
                <div className="container">
                    <div className="banner-content text-center">
                        <h1 className="banner-title">Explorez l'apprentissage <br />sans frontières</h1>
                        <div className="desc mb-34">Découvrez une expérience d'apprentissage <br />à distance enrichissante et adaptable.</div>
                        <div className="banner-btn">
                            <Link style={{backgroundColor:"#AB1827",borderRadius: "51px",width: "213px",height: "52.65px",padding: "16px 24px", fontFamily:"'MetropolisMedium', sans-serif",fontStyle: "normal",fontWeight: "100",fontSize: "18px",lineHeight: "145%", color:"white"}} className="" to="/course">Explorer nos cours</Link>
                        </div>
                    </div>
                </div>
            </div>            
            {/* <!-- banner section end --> */}            
        </React.Fragment>
    );
}

export default BannerDefault;