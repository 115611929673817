import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@headlessui/react';
import NotificationService from '../../../services/notification';
import Logo from "../../../utils/Logo";
import logo from "../../../assets/logo/logo.jpeg";
import elearning from 'assets/img/courses/elearning.png';
import visio from 'assets/img/courses/visio.png';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const AccountTypeSelection = () => {
    const navigate = useNavigate();
    const accounts = [
        { id: 1,title:'formateur', name: 'Je suis enseignant', image: elearning },
        { id: 2, title:'utilisateur', name: 'Je suis apprenant', image: visio },
    ];

    const [selectedAccount, setSelectedAccount] = useState(accounts[0]);

    

    const handleNext = () => {
        if (selectedAccount) {
            navigate('/register', { state: { selectedAccount } });
        } else {
            NotificationService.error("Veuillez sélectionner un type de compte");
        }
    };

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <div className="hidden md:block md:w-1/2 bg-blue-50">
                {/* This section is intentionally left blank with background */}
            </div>
            <div className="w-full md:w-1/2 flex items-center justify-center bg-white p-6">
                <div className="w-full max-w-md flex flex-col justify-center h-full">
                    <div className="text-center mt-12 mb-6">
                        <Logo classname="h-10 mx-auto mb-4" logo={logo} />
                        <h1 className="text-2xl font-bold mb-2">Inscrivez-vous</h1>
                        <p className="text-gray-500">
                            J'ai déjà un compte, <a href="/login" className="text-red-600">Me connecter</a>
                        </p>
                    </div>
                    <div className="flex justify-center space-x-4 mb-12">
                        {accounts.map((account) => (
                            <div
                                key={account.id}
                                className={`relative flex flex-col items-center p-4 border rounded-lg cursor-pointer transition-all ${
                                    selectedAccount.id === account.id ? 'border-red-600 bg-red-50' : 'border-gray-300'
                                }`}
                                onClick={() => setSelectedAccount(account)}
                            >
                                {selectedAccount.id === account.id && (
                                    <CheckCircleIcon className="absolute top-2 right-2 w-6 h-6 text-red-600" />
                                )}
                                <img src={account.image} alt={account.name} className="w-20 h-20 mb-2" />
                                <p className="text-center">{account.name}</p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-auto mb-6">
                        <Button
                            className="w-full rounded-full bg-red-600 py-3 text-white hover:bg-red-500 active:bg-red-700"
                            onClick={handleNext}
                        >
                            Suivant
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountTypeSelection;
