import NotificationService from 'services/notification'
import { createModule } from '../../services/module'
import { useState } from 'react'

export default function Module() {
  const [module, setModule] = useState('')
  const [categorie, setCategorie] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault() 
    const data = {
      name: module,
      category_id: Number(categorie)
    }
    try {
      await createModule(data).then((response) => {
        NotificationService.success("Module ajouté avec succès");
      })

    } catch (e) {
      console.error(e);
      NotificationService.error("Erreur lors de l'ajout du module");
    }
  }
  return (
    <div className="w-full max-w-[37rem] p-4 bg-white h-[56%] mt-[80px] border ml-[27%] border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
  
        <h5 className="text-xl font-medium text-gray-900 dark:text-white mt-2">Ajouter un module</h5>
        <div className='mb-8'>
          <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom du module</label>
          <input type="text" name="module" onChange={(e) => { setModule(e.target.value) }} id="module" placeholder="Nom" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
        </div>
        <div className='mb-16'>
          <label for="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Choix du categories</label>
          <select id="countries" onChange={(e) => { setCategorie(e.target.value) }} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
            <option selected>Choisir le categorie</option>
            {JSON.parse(localStorage.getItem('categories')).map((categorie, index) => (
              <option key={index} value={categorie.id}>{categorie.name}</option>
            ))}
          </select>

        </div>

        <button  onClick={handleSubmit} className="w-full text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">valider</button>

    </div>

  )
}