import React from 'react';
import styled from 'styled-components';

import img2 from 'assets/img/about/Rectangle 737.png'
import img3 from 'assets/img/about/Rectangle 738.png'

const HeroSection = styled.section`
  width: 80%;
  margin: 4rem auto;
  background-color: #ffffff;
`;

const HeroTextContainer = styled.div`
  margin-bottom: 2rem;
`;

const TitleMarker = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const MarkerLine = styled.div`
  width: 50px;
  height: 2px;
  background-color: #800000;
  margin-right: 1rem;
`;

const SectionLabel = styled.span`
  color: #800000;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const HeroTextRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  color: #000;
  flex: 1;
`;

const HeroSubtitle = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin: 0;
  max-width: 50%;
`;

const HeroImagesContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const HeroImageLarge = styled.img`
  flex: 2;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const HeroImageSmall = styled.img`
  flex: 1;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Hero = () => (
  <HeroSection>
    <HeroTextContainer>
      <TitleMarker>
        <MarkerLine />
        <SectionLabel>Lorem Ipsum</SectionLabel>
      </TitleMarker>
      <HeroTextRow>
        <HeroTitle>Lorem ipsum dolor sit amet consectetur.</HeroTitle>
        <HeroSubtitle>
          Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus.
        </HeroSubtitle>
      </HeroTextRow>
    </HeroTextContainer>
    <HeroImagesContainer>
      <HeroImageLarge src={img2} />
      <HeroImageSmall src={img3}/>
    </HeroImagesContainer>
  </HeroSection>
);

export default Hero;
