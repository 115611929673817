import axios  from "axios";

import { API_BASE_URL, headers } from ".";
export const token = localStorage.getItem('token');


export const createModule = (data) => {
    return axios.post(`${API_BASE_URL}/admin/module`,data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getModuleByCategory = (data) => {
    return axios.post(`${API_BASE_URL}/instructor/modules/category`,data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}