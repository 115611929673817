import { useState } from 'react'
import { CiUser } from "react-icons/ci";
import { Field, Input, Label, Button } from '@headlessui/react'
import NotificationService from '../../../services/notification';
import {sendPasswordResetEmail} from '../../../services/auth';
import { useNavigate } from 'react-router-dom';

function RessetMail() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                email: email,
            };
          
            await sendPasswordResetEmail(data).then(response => {
                if (response.data.success) {
                    NotificationService.success("Un lien de réinitialisation a été envoyé à votre adresse e-mail");
                    navigate('/');
                } else {
                    NotificationService.error(response.data.message);
                }
            });
        } catch (error) {
            NotificationService.error("Une erreur s‘est produite. Veuillez réessayer");
        }
    }
    return (
        <div className="max-w-[600px] mx-auto flex flex-col items-center  px-8 ">
            <Field className={"w-full mb-10"}>
                <Label>Email</Label>
                <div className='relative w-full mt-2'>
                    <CiUser size={25} className='absolute top-4 left-6' />
                    <Input style={{ paddingLeft: '3rem' }} className={"border data-[focus]:shadow  border-gray-500 py-3 pr-4 rounded-full w-full pl-16"} onChange={(e) => setEmail(e.target.value)} name="full_name" type="text" placeholder="" />
                </div>
            </Field>

            <div className="w-full flex flex-1 ">
                <Button className="rounded-full bg-red-600 py-3 px-4 text-sm text-white data-[hover]:bg-red-500 data-[active]:bg-red-700  w-full" onClick={handleSubmit}>
                    Envoyer
                </Button>
            </div>



        </div>
    );
}

export default RessetMail;