import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaTimes, FaSlidersH, FaSearch, FaFileAlt } from 'react-icons/fa';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import image from 'assets/img/courses/home8/2.jpg';
import ReactPaginate from 'react-paginate';
import { getCourseAll } from 'services/course';

const CoursesContainer = styled.div`
  padding: 20px;
  width: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const SectionTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const FilterButton = styled.button`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background: #f8f9fa;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
`;

const SearchBar = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 10px;
  color: #666;
`;

const CourseList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CourseItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
`;

const CourseImage = styled.img`
  width: 179px;
  height: 100px;
  margin-right: 15px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const CourseInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const CourseTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CourseDetails = styled.div`
  color: #666;
  font-size: 14px;
`;

const CourseDetail = styled.span`
  margin-right: 10px;
  &::after {
    content: '•';
    margin-left: 10px;
  }
  &:last-child::after {
    content: '';
    margin: 0;
  }
`;

const CourseDuration = styled.div`
  color: #666;
  font-size: 14px;
`;

const Progress = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressCircle = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 15px;
`;

const ActionButton = styled.button`
  background: ${props => props.primary ? '#F7F7F7' : '#fff'};
  color: ${props => props.primary ? '#26272B' : '#dc3545'};
  border: 1px solid #F7F7F7;
  padding: 0.5em 1em;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};

  &:hover {
    background: ${props => props.primary ? '#fff' : '#f8f9fa'};
  }

  @media (max-width: 768px) {
    padding: 0.3em 0.6em;
    font-size: 0.8em;
    width: 80%;
    margin-left: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .pagination {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      margin: 0 5px;

      &.selected a {
        background-color: #AB1827;
        color: white;
        border-radius: 50%;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid #ddd;
        border-radius: 50%;
        cursor: pointer;
        color: #333;
        background-color: #fff;

        &:hover {
          background-color: #f8f9fa;
        }
      }
    }

    @media (max-width: 768px) {
      li a {
        width: 25px;
        height: 25px;
        font-size: 12px;
      }
    }
  }
`;

const MyCourses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalElement, setTotalElement] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const data = {
      page,
      per_page: 10,
      search
    };
    getCourseAll(data)
      .then(response => {
        setCourses(response.data.data.data);
        setTotalElement(response.data.data.total);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }, [page, search]);


  return (
    <CoursesContainer>

      <SectionTitle>Mes cours</SectionTitle>
      <Controls>
        <FilterButton>
          <FaSlidersH style={{ marginRight: '5px' }} />
          Filtre
        </FilterButton>
        <SearchContainer>
          <SearchBar placeholder="Rechercher un cours" onChange={(e) => setSearch(e.target.value)} />
          <SearchIcon />
        </SearchContainer>
      </Controls>
      {loading ? (
        <div className="flex justify-center mt-[300px]">
          <p className="text-lg">Chargement...</p>
        </div>
      ) : (
        <>
          {courses.length === 0 ? (
            <div className="flex justify-center mt-[300px]">
              <p className="text-lg">Pas de cours disponible</p>
            </div>
          ) : (
            <>
              <CourseList>
                {courses.map((course, index) => (
                  <CourseItem key={index}>
                    <CourseImage src={course?.cover && course.cover.length > 0 ? course.cover : image} alt={course.title} />
                    <CourseInfo>
                      <CourseTitle>{course.title}</CourseTitle>
                      <CourseDetails>
                        <CourseDetail>{course.category.name}</CourseDetail>
                        <CourseDetail>{course.user.full_name}</CourseDetail>
                      </CourseDetails>
                      <CourseDuration>{course?.duration} H</CourseDuration>
                    </CourseInfo>
                    <Progress>
                      <ProgressCircle>
                        <CircularProgressbar
                          value={40}
                          text={`${40}%`}
                          styles={buildStyles({
                            textColor: "#dc3545",
                            pathColor: "#dc3545",
                            trailColor: "#f5f5f5"
                          })}
                        />
                      </ProgressCircle>
                      <ActionButton primary visible={course.progress === 100}>
                        <FaFileAlt style={{ marginRight: '5px' }} />
                        Certification
                      </ActionButton>
                    </Progress>
                    <ActionButton>
                      <FaTimes />
                    </ActionButton>
                  </CourseItem>
                ))}
              </CourseList>

              <PaginationContainer>
                <ReactPaginate
                  initialPage={0}
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={(v) => {
                    setPage(v.selected + 1)
                  }}
                  pageRangeDisplayed={5}
                  previousLabel="<"
                  pageCount={Math.ceil(totalElement / 10)}
                  marginPagesDisplayed={2}
                  containerClassName={"pagination"}
                />
              </PaginationContainer>
            </>
          )}
        </>
      )}
    </CoursesContainer>
  );
};

export default MyCourses;
