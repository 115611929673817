import React, { useState } from "react";

// Event Images
import galleryImg1 from '../../assets/img/gallery/1.jpg';
import galleryImg2 from '../../assets/img/gallery/2.jpg';
import galleryImg3 from '../../assets/img/gallery/3.jpg';
import galleryImg4 from '../../assets/img/gallery/4.jpg';
import galleryImg5 from '../../assets/img/gallery/5.jpg';
import galleryImg6 from '../../assets/img/gallery/6.jpg';
import galleryImg7 from '../../assets/img/gallery/7.jpg';
import galleryImg8 from '../../assets/img/gallery/8.jpg';
import galleryImg9 from '../../assets/img/gallery/9.jpg';
import galleryImg10 from '../../assets/img/gallery/10.jpg';
import galleryImg11 from '../../assets/img/gallery/11.jpg';
import galleryImg12 from '../../assets/img/gallery/12.jpg';

const photos = [
    galleryImg1,
    galleryImg2,
    galleryImg3,
    galleryImg4,
    galleryImg5,
    galleryImg6,
    galleryImg7,
    galleryImg8,
    galleryImg9,
    galleryImg10,
    galleryImg11,
    galleryImg12
];

const GalleryPart = () => {
    const [currentImage, setCurrentImage] = useState(null);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(null);
        setViewerIsOpen(false);
    };

    return (
        <>
            <div className="rs-gallery pt-100 pb-70 md-pt-80 md-pb-50">
                <div className="container">
                    <div className="gallery">
                        {photos.map((photo, index) => (
                            <div key={index} className="gallery-item" onClick={() => openLightbox(index)}>
                                <img src={photo} alt={`Image ${index}`} />
                            </div>
                        ))}
                    </div>
                    {currentImage !== null && (
                        <div className="modal">
                            <div className="modal-content">
                                <span className="close" onClick={closeLightbox}>&times;</span>
                                <img src={photos[currentImage]} alt="modal" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default GalleryPart;
