import { Link } from 'react-router-dom';

const ErrorContent = () => {
    return (
        <div id="rs-page-error" className="rs-page-error">
            <div className="error-text">
                <h1 className="error-code mb-3">404</h1>
                <h3 className="error-message mb-7">Oups ! Page Introuvable (404)</h3>
                <p className="text-lg mb-6">Il semble que la page que vous cherchez n'existe pas ou a été déplacée.</p>
                <p className="text-lg mb-6">Si vous avez besoin d'aide, n'hésitez pas à nous <Link to="/contact">contacter</Link>.</p>
                <Link className="readon orange-btn" to="/" title="HOME">Vous pouvez retourner à la page d'accueil en cliquant ici</Link>
            </div>
        </div>
    );
}

export default ErrorContent;