import AWS from 'aws-sdk';

const S3_BUCKET = process.env.REACT_APP_NOM_BUCKET;
const REGION = process.env.REACT_APP_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_KEY_ID,
  secretAccessKey: process.env.REACT_APP_BUCKET_KEY,
  region: REGION,
});

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});


const uploadToS3 = (file) => {
  if (!(file instanceof Blob)) {
    file = new Blob([file]);
  }
  const fileNameWithoutSpaces = file.name.replace(/\s+/g, '-');
  const params = {
    Bucket: "started-bucket",
    Key: `${Date.now()}-${fileNameWithoutSpaces}`,
    Body: file,
  };
  return s3.upload(params).promise();
};

export default s3;
