import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@headlessui/react';
import { useAuth } from 'contexts/AuthProvider';
import { checkIfMailExist, editProfile } from 'services/user';
import NotificationService from 'services/notification';
import { Field, Select } from '@headlessui/react';
import clsx from 'clsx';
import { country } from 'services/country';

const ProfileContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  display: flex;
  flex-direction: column; /* Change to column for responsiveness */
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ProfileDetails = styled.div`
  width: 100%;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    margin-bottom: 50px;
    
  }
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 150px;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  background-color: #ab1927;
  color: white;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #ab1927;
  }
`;

const ProfilePage = () => {
  const { user } = useAuth();
  const [formValues, setFormValues] = useState({
    id: '',
    nom: '',
    prenom: '',
    address: '',
    birth_day: '',
    phone: '',
    email: user?.email || '',
    country: '',
    city: '',
    postal_code: '',
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        id: user.id || '',
        nom: user.nom || '',
        prenom: user.prenom || '',
        address: user.address || '',
        birth_day: user.birth_day || '',
        phone: user.phone || '',
        email: user.email || '',
        country: user.country || '',
        city: user.city || '',
        postal_code: user.postal_code || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        email: formValues.email,
      };
      if (formValues.email !== user.email) {
        const response = await checkIfMailExist(data);

        if ((response.data.success && response.data.data.exist)) {
          NotificationService.error('Email déjà utilisé');
          return;
        }
      }
      await editProfile(formValues).then((response) => {
        if (response.data.success) {
          NotificationService.success('Profil modifié avec succès');
        } else {
          NotificationService.error('Erreur lors de la modification du profil');
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ProfileContainer>
      <ProfileDetails>
        <SectionTitle>Informations personnelles</SectionTitle>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Nom</Label>
            <Input
              type="text"
              name="nom"
              value={formValues.nom}
              onChange={handleChange}
              placeholder="Nom"
            />
          </FormGroup>
          <FormGroup>
            <Label>Prénom</Label>
            <Input
              type="text"
              name="prenom"
              value={formValues.prenom}
              onChange={handleChange}
              placeholder="Prénom"
            />
          </FormGroup>
          <FormGroup>
            <Label>Adresse</Label>
            <Input
              type="text"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              placeholder="Adresse"
            />
          </FormGroup>
          <FormGroup>
            <Label>Date de naissance</Label>
            <Input
              type="date"
              name="birth_day"
              value={formValues.birth_day ? new Date(formValues.birth_day).toISOString().split('T')[0] : ''}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>N° de téléphone</Label>
            <Input
              type="tel"
              name="phone"
              value={formValues.phone}
              onChange={handleChange}
              placeholder="N° de téléphone"
            />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Pays</Label>
            <Field>
              <div className="relative">
                <Select
                  name='country'
                  value={formValues.country}
                  onChange={handleChange}
                  className={clsx(
                    'mt-3 block w-full appearance-none rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-black',
                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-black/25',
                    'border-gray-300' // Ajouter une classe de bordure
                  )}
                >
                  {country.map((item) => (
                    <option key={item.code} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
            </Field>
          </FormGroup>
          <FormGroup>
            <Label>Ville</Label>
            <Input
              type="text"
              name="city"
              value={formValues.city}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Code Postal</Label>
            <Input
              type="text"
              name="postal_code"
              value={formValues.postal_code}
              onChange={handleChange}
            />
          </FormGroup>
          <ButtonContainer>
            <StyledButton type="submit">Valider</StyledButton>
          </ButtonContainer>
        </Form>
      </ProfileDetails>
    </ProfileContainer>
  );
};

export default ProfilePage;
