import React, { useState, useCallback } from 'react';
import { CiUser, CiLock } from "react-icons/ci";
import { FcGoogle } from "react-icons/fc";
import { FaEye } from "react-icons/fa";
import { register } from '../../../services/auth';
import NotificationService from '../../../services/notification';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Field, Input, Label, Button, Switch } from '@headlessui/react';
import axios from "axios";
import { useGoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';

const RegisterForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedAccount } = location.state || {};
    const [password, setPassword] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [enabled, setEnabled] = useState(false);

    const onLoginStart = useCallback(() => {
        alert('login start');
    }, []);

    const buttonStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '9999px', // Utiliser '50%' pour obtenir un bouton totalement rond si nécessaire
        backgroundColor: 'rgb(209 213 219)', // bg-gray-300
        padding: '18px 28px', // py-2 px-4 (8px pour py-2 et 16px pour px-4)
        fontSize: '0.875rem',
        margin: '0px',
        height: '37px', // text-sm (14px)
        color: 'black',
        width: '100%', // w-full
    };

    const handleSubmit = async (e) => {
        if (captchaValue) {
            e.preventDefault();
            const data = {
                email: email,
                password: password,
                login_by: 'manuel',
                nom: nom,
                prenom: prenom,
                role: selectedAccount ? selectedAccount.title.toLowerCase() : 'utilisateur'
            };

            if (password !== confirmPassword) {
                NotificationService.error("Les mots de passe ne correspondent pas");
                return;
            }

            if (password.length < 8) {
                NotificationService.error("Le mot de passe doit contenir au moins 8 caractères");
                return;
            }

            try {
                const response = await register(data);
                if (response.data.success) {
                    NotificationService.success("Inscription réussie, veuillez confirmer votre adresse mail");
                    navigate('/');
                } else {
                    NotificationService.error(response.data.message);
                }
            } catch (error) {
                NotificationService.error("Une erreur s‘est produite. Veuillez réessayer");
            }
        } else {
            NotificationService.error("Veuillez compléter le CAPTCHA");
        }
    };

    const googleRegister = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => res.data);

            const data = {
                email: userInfo.email,
                nom: userInfo.family_name,
                prenom: userInfo.given_name,
                password: userInfo.sub,
                login_by: 'google',
                picture: userInfo.picture
            };

            try {
                const response = await register(data);
                if (response.data.success) {
                    NotificationService.success("Connexion réussie");
                    localStorage.setItem('token', response.data.data.token);
                    navigate('/dashboard');
                } else {
                    NotificationService.error(response.data.error);
                }
            } catch (error) {
                NotificationService.error("Une erreur s'est produite lors de la connexion avec Google");
            }
        },
    });

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    return (
        <div className="max-w-[600px] mx-auto flex flex-col items-center px-8">
            <Field className="w-full mb-4">
                <Label>Nom</Label>
                <div className='relative w-full mt-1'>
                    <CiUser size={25} className='absolute top-1/2 transform -translate-y-1/2 left-4' />
                    <Input
                        style={{ paddingLeft: '3rem' }}
                        className="border focus:shadow border-gray-500 py-3 pr-4 rounded-full w-full"
                        name="nom"
                        value={nom}
                        type="text"
                        placeholder=""
                        onChange={(e) => setNom(e.target.value)}
                    />
                </div>
            </Field>
            <Field className="w-full mb-4">
                <Label>Prénom</Label>
                <div className='relative w-full mt-1'>
                    <CiUser size={25} className='absolute top-1/2 transform -translate-y-1/2 left-4' />
                    <Input
                        style={{ paddingLeft: '3rem' }}
                        className="border focus:shadow border-gray-500 py-3 pr-4 rounded-full w-full"
                        name="prenom"
                        value={prenom}
                        type="text"
                        placeholder=""
                        onChange={(e) => setPrenom(e.target.value)}
                    />
                </div>
            </Field>
            <Field className="w-full mb-4">
                <Label>Email</Label>
                <div className='relative w-full mt-1'>
                    <CiUser size={25} className='absolute top-1/2 transform -translate-y-1/2 left-4' />
                    <Input
                        style={{ paddingLeft: '3rem' }}
                        className="border focus:shadow border-gray-500 py-3 pr-4 rounded-full w-full"
                        name="email"
                        value={email}
                        type="text"
                        placeholder="contact@akambiconsulting.com"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            </Field>
            <Field className="w-full mb-4">
                <Label>Mot de passe *</Label>
                <div className='relative w-full mt-1'>
                    <CiLock size={25} className='absolute top-1/2 transform -translate-y-1/2 left-4' />
                    <Input
                        style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                        className="border focus:shadow border-gray-500 py-3 rounded-full w-full"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="button"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <FaEye size={25} />
                    </Button>
                </div>
            </Field>
            <Field className="w-full mb-4">
                <Label>Confirmation de mot de passe *</Label>
                <div className='relative w-full mt-1'>
                    <CiLock size={25} className='absolute top-1/2 transform -translate-y-1/2 left-4' />
                    <Input
                        style={{ paddingLeft: '3rem', paddingRight: '3rem' }}
                        className="border focus:shadow border-gray-500 py-3 rounded-full w-full"
                        name="confirm"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        type="button"
                        className="absolute top-1/2 transform -translate-y-1/2 right-4"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                        <FaEye size={25} />
                    </Button>
                </div>
            </Field>
            <div className="flex w-full justify-between mb-4">
                <div>
                    <Field className="flex items-center gap-2">
                        <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className={`${enabled ? 'bg-blue-500' : 'bg-gray-200'}
                                relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                            <span className="sr-only">Se souvenir de moi</span>
                            <span
                                className={`${enabled ? 'translate-x-6' : 'translate-x-1'}
                                    inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </Switch>
                        <Label>Se souvenir de moi</Label>
                    </Field>
                </div>

                <div>
                    <Link to="/reset-mail" className="text-red-500">Mot de passe oublié</Link>
                </div>
            </div>
            <div className="flex justify-start py-4 w-full">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_PROD}
                    onChange={handleCaptchaChange}
                />
            </div>

            <div className="w-full flex mb-4">
                <Button
                    className="rounded-full bg-red-600 py-3 px-4 text-sm text-white hover:bg-red-500 active:bg-red-700 w-full"
                    onClick={handleSubmit}
                >
                    Inscription
                </Button>
            </div>

            <div className="flex flex-col items-center py-2">
                <p className="bg-red-red">Ou s'inscrire avec</p>
            </div>

            <div className="flex gap-4 mb-4 items-center">
                <LoginSocialFacebook
                    isOnlyGetToken
                    fields="name,email,picture"
                    appId='871576458320784'
                    onLoginStart={onLoginStart}
                    scope="email"
                    onResolve={({ provider, data }) => {
                        // setProvider(provider);
                        // setProfile(data);
                    }}
                    onReject={(err) => {
                        console.log(err);
                    }}
                >
                    <FacebookLoginButton 
                        iconSize='20px'
                        iconFill='white'
                        style={{ ...buttonStyle }}
                        text='Facebook'
                        activeStyle={{backgroundColor: 'gray-400'}}
                        iconColor='blue'
                    />
                </LoginSocialFacebook>
                <Button
                    className="flex justify-center items-center rounded-full bg-gray-300 py-2 px-4 text-sm text-black hover:bg-gray-400 active:bg-gray-500 w-full"
                    onClick={googleRegister}
                >
                    <FcGoogle size={20} className="mr-2" /> <span>GOOGLE</span>
                </Button>
            </div>
        </div>
    );
};

export default RegisterForm;
