import axios from "axios";
import { API_BASE_URL ,TOKEN} from ".";
export const token = localStorage.getItem('token');

console.log(token);

export const getCourses = (data) => {
    return axios.get(`${API_BASE_URL}/courses`,data,{});
};

export const getCourse = (id) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, id);
};

export const createCourse = (data) => {
    return axios.post(`${API_BASE_URL}/create/course`, data,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
};

export const getCourseAll = (data) => {
    return axios.get(`${API_BASE_URL}/courses`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        page: data.page,
        per_page: data.per_page,
        search: data.search
      }
    });
  }

export const getAllCourseForAllUser = (data) => {
    return axios.get(`${API_BASE_URL}/all/courses`, {
      params: {
        page: data.page,
        per_page: data.per_page,
        search: data.search
      }
    });
}

export const updateCourse = (data) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, data);
}

export const deleteCourse = (id) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, id);
};

export const getCourseSections = (id) => {
    return axios.post(`${API_BASE_URL}/admin/course-sections`, id);
};

// get course by category

export const getCourseByCategory = (id) => {
    return axios.post(`${API_BASE_URL}/admin/courses`, id);
};


// get latest courses

export const getLastCourses = () => {

    return axios.get(`${API_BASE_URL}/latest-courses`)
        .then(response => response.data)
        .catch(error => {
            //console.error('Error fetching latest courses:', error);
            throw error;
        });
};