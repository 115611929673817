import React, { useState } from 'react';
import styled from 'styled-components';
import SectionTitle from '../../components/Common/SectionTitle';
import ModalVideo from 'react-modal-video';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className='mb-100 mt-100'>
            <ModalVideo channel='youtube' isOpen={isOpen} videoId='YOUR_VIDEO_ID' onClose={openModal} />
            <div className="container" style={{ maxWidth: '75%', margin: '0 auto' }}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="main-part">
                            <SectionTitle
                                sectionClass="sec-title"
                                subtitleClass="sub-title primary"
                                subtitle={<div>SUBJECT CATEGORIES</div>}
                                titleClass="title mb-0"
                                title="Frequently Asked Questions"
                            />
                            <div className="faq-content">
                                <Accordion className="accordion-style1" preExpanded={['a']}>
                                    <AccordionItem className="accordion-item" uuid="a">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                                What are the requirements?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis. Vitae rhoncus cursus aenean aliquam id morbi fermentum non eget.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="b">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                                Does Educavo offer free courses?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis. Vitae rhoncus cursus aenean aliquam id morbi fermentum non eget.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="c">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                                What is the transfer application process?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis. Vitae rhoncus cursus aenean aliquam id morbi fermentum non eget.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem className="accordion-item" uuid="d">
                                        <AccordionItemHeading className="card-header">
                                            <AccordionItemButton className="accordion-button">
                                                What is distance education?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="card-body">
                                            Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis. Vitae rhoncus cursus aenean aliquam id morbi fermentum non eget.
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq;
