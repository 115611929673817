import React, { useState } from 'react';
import styled from 'styled-components';

const StepContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: auto;
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px;
    margin: 0 10px;
  }
`;

const StepHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  color: #6c757d;
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px;
  background-color: #ffffff;
`;

const QuestionInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const QuestionNumber = styled.div`
  width: 10%;
  max-width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
  background-color: #f8f9fa;
`;

const QuestionInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
  width: 100%;
`;

const Chevron = styled.div`
  width: 10%;
  max-width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #f8f9fa;
  cursor: pointer;
`;

const OptionInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const OptionInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
  width: 100%;
`;

const OptionIndicator = styled.div`
  width: 10%;
  max-width: 40px;
  height: 40px;
  border: 1px solid ${(props) => (props.isCorrect ? '#b61c1c' : '#e0e0e0')};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
  background-color: ${(props) => (props.isCorrect ? '#b61c1c' : '#f8f9fa')};
  cursor: pointer;

  &:after {
    content: '✔';
    color: ${(props) => (props.isCorrect ? '#ffffff' : 'transparent')};
    font-size: 16px;
  }
`;

const DeleteButton = styled.button`
  width: 10%;
  max-width: 40px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background-color: #f8f9fa;
  cursor: pointer;

  &:hover {
    color: #b61c1c;
    border-color: #b61c1c;
  }
`;

const AddOptionButton = styled.button`
  padding: 10px;
  border: 1px solid #f8f9fa;
  border-radius: 5px;
  background-color: #f8f9fa;
  cursor: pointer;
  font-size: 16px;
`;

const AddQuestionButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #b61c1c;
  color: white;
  cursor: pointer;
  font-size: 16px;
`;

const StepSix = ({ data, setData }) => {
  const [questions, setQuestions] = useState(data.qcm.length > 0 ? data.qcm : [{ question: '', options: ['', ''], correctAnswers: [0] }]);
  const [collapsed, setCollapsed] = useState(new Array(questions.length).fill(false));

  const addQuestion = () => {
    const newQuestions = [...questions, { question: '', options: ['', ''], correctAnswers: [0] }];
    setQuestions(newQuestions);
    setCollapsed([...collapsed, false]);
    setData({ ...data, qcm: newQuestions });
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
    setData({ ...data, qcm: newQuestions });
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
    setData({ ...data, qcm: newQuestions });
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push('');
    setQuestions(newQuestions);
    setData({ ...data, qcm: newQuestions });
  };

  const deleteOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(newQuestions);
    setData({ ...data, qcm: newQuestions });
  };

  const handleCorrectAnswerChange = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    const correctAnswers = newQuestions[questionIndex].correctAnswers;
    if (correctAnswers.includes(optionIndex)) {
      newQuestions[questionIndex].correctAnswers = correctAnswers.filter((index) => index !== optionIndex);
    } else {
      newQuestions[questionIndex].correctAnswers.push(optionIndex);
    }
    setQuestions(newQuestions);
    setData({ ...data, qcm: newQuestions });
  };

  const toggleCollapse = (index) => {
    const newCollapsed = [...collapsed];
    newCollapsed[index] = !newCollapsed[index];
    setCollapsed(newCollapsed);
  };

  return (
    <StepContainer>
      <StepHeader>Ajouter un QCM</StepHeader>
      <Description>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</Description>

      {questions.map((q, qIndex) => (
        <FormGroup key={qIndex}>
          <QuestionInputContainer>
            <QuestionNumber>Q{qIndex + 1}</QuestionNumber>
            <QuestionInput
              type="text"
              placeholder="Votre question"
              value={q.question}
              onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
            />
            <Chevron onClick={() => toggleCollapse(qIndex)}>
              {collapsed[qIndex] ? '▼' : '▲'}
            </Chevron>
          </QuestionInputContainer>
          {!collapsed[qIndex] && q.options.map((option, oIndex) => (
            <OptionInputContainer key={oIndex}>
              <OptionIndicator
                isCorrect={q.correctAnswers.includes(oIndex)}
                onClick={() => handleCorrectAnswerChange(qIndex, oIndex)}
              />
              <OptionInput
                type="text"
                placeholder={`Réponse ${oIndex + 1}`}
                value={option}
                onChange={(e) => handleOptionChange(qIndex, oIndex, e.target.value)}
              />
              <DeleteButton onClick={() => deleteOption(qIndex, oIndex)}>✕</DeleteButton>
            </OptionInputContainer>
          ))}
          {!collapsed[qIndex] && <AddOptionButton onClick={() => addOption(qIndex)}>+ Ajouter une réponse</AddOptionButton>}
        </FormGroup>
      ))}

      <AddQuestionButton onClick={addQuestion}>+ Ajouter une question</AddQuestionButton>
    </StepContainer>
  );
};

export default StepSix;
