import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import dollar from 'assets/img/courses/dollar.png';
import eur from 'assets/img/courses/eur.png';
import fcfa from 'assets/img/courses/eur.png';

const StepContainer = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const StepHeader = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1.25rem;

  @media (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

const ImportantNotice = styled.div`
  background-color: #fcebea;
  border: 1px solid #f5c6cb;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.3125rem;
`;

const ImportantTitle = styled.div`
  font-weight: bold;
  color: #b61c1c;
  margin-bottom: 0.5rem;
`;

const CurrencyOption = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  margin-bottom: 1.25rem;
  justify-content: space-between;
`;

const CurrencyButton = styled.div`
  background: ${(props) => (props.selected ? '#f5f5f5' : 'white')};
  border: ${(props) => (props.selected ? '2px solid #b61c1c' : '1px solid #ccc')};
  padding: 1.25rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  transition: background 0.3s, border 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 150px;

  &:hover {
    background: #f5f5f5;
    border: 2px solid #b61c1c;
  }

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const CurrencyIcon = styled.img`
  width: 3.125rem;
  height: 3.125rem;
  margin-bottom: 0.625rem;

  @media (max-width: 480px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  border: 1px solid #ccc;
  border-radius: 0.3125rem;

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.625rem;
  font-weight: bold;
`;

const DurationContainer = styled.div`
  margin-top: 1.25rem;
`;

const StepFour = ({ data, setData, courseType }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(data.price.currency || '');
  const [amount, setAmount] = useState(data.price.amount || '');
  const [duration, setDuration] = useState(data.duration || '');

  // Effect to update local state when data changes
  // useEffect(() => {
  //   setSelectedCurrency(data.price.currency || '');
  //   setAmount(data.price.amount || '');
  //   setDuration(data.duration || '');
  // }, [data]);

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
    setData({
      ...data,
      price: {
        ...data.price,
        currency: currency
      }
    });
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(value);
    setData({
      ...data,
      price: {
        ...data.price,
        amount: value
      }
    });
  };

  const handleDurationChange = (e) => {
    const { value } = e.target;
    setDuration(value);
    setData({
      ...data,
     price:{
      ...data.price,
      duration: value
     }
    });
  };

  return (
    <StepContainer>
      <StepHeader>Proposition de prix</StepHeader>
      <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</p>

      {courseType === 'visio-conference' ? (
        <ImportantNotice>
          <ImportantTitle>Important</ImportantTitle>
          <p>Un conseiller pédagogique prendra contact avec vous dans les meilleurs délais pour identifier vos besoins et vous adresser un devis</p>
        </ImportantNotice>
      ) : (
        <div>
          <Label>Devise</Label>
          <CurrencyOption>
            <CurrencyButton
              selected={selectedCurrency === 'CFA'}
              onClick={() => handleCurrencySelect('CFA')}
            >
              <CurrencyIcon src={fcfa} alt="Franc CFA" />
              Franc CFA
            </CurrencyButton>
            <CurrencyButton
              selected={selectedCurrency === 'EUR'}
              onClick={() => handleCurrencySelect('EUR')}
            >
              <CurrencyIcon src={eur} alt="Euro" />
              Euro
            </CurrencyButton>
            <CurrencyButton
              selected={selectedCurrency === 'USD'}
              onClick={() => handleCurrencySelect('USD')}
            >
              <CurrencyIcon src={dollar} alt="Dollar" />
              Dollar
            </CurrencyButton>
          </CurrencyOption>

          <Label>Montant</Label>
          <Input
            type="number"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Montant"
          />

          <DurationContainer>
            <Label>Durée de la formation (en heures)</Label>
            <Input
              type="number"
              value={duration}
              onChange={handleDurationChange}
              placeholder="Durée"
            />
          </DurationContainer>
        </div>
      )}
    </StepContainer>
  );
};

export default StepFour;
