import React from 'react';
import { Link } from 'react-router-dom';

const CourseSingle = (props) => {
    const renderStars = (rating) => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <i key={i} className={i < rating ? 'fa fa-star' : 'fa fa-star-o'}></i>
            );
        }
        return stars;
    };

    return (
        <div className={props.itemClass}>
            <div className="courses-grid">
                <div className="img-part">
                    <Link to="/course/course-single">
                        <img
                            src={props.image}
                            alt={props.title}
                        />
                    </Link>
                </div>

                <div className="content-part">
                    <div className="course-price">
                        <span className="price">{props.pricing} {props.devise}</span>
                    </div>
                    <h3 className="title">
                        <Link to="/course/course-single"><h6>{props.title}</h6></Link>
                    </h3>
                    <ul className="info-meta">
                        <li style={{ textAlign: 'right', display: 'inline-block', width: '100%', fontSize:'12px'}} className="ratings">
                            <li style={{ float: 'left', display: 'inline-block'}}>
                                <p>{props.author} Gustave ALI COUDI</p>
                            </li>
                            {renderStars(props.ratings)}
                            <span>{props.ratings}</span>
                        </li>
                    </ul>
                    <ul className="meta-part">
                        <li className="user">
                            <i className="fa fa-user"></i>
                            {props.studentQuantity}21 Etudiants
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CourseSingle;
