import React from 'react';
import styled from 'styled-components';
import img1 from 'assets/img/about/Rectangle 739.png';

const Container = styled.div`
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const TitleIndicator = styled.span`
  background-color: #800000;
  width: 50px;
  height: 2px;
  margin-right: 10px;
`;

const TitleLabel = styled.span`
  color: #800000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
`;

const Title = styled.h2`
  color: #000;
  font-size: 36px;
  font-weight: bold;
  margin: 0;
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 16px;
  margin-top: 5px;
`;

const InstructorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;

const InstructorCard = styled.div`
  text-align: left;
`;

const InstructorImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
  //border-radius: 8px;
`;

const InstructorName = styled.h3`
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const InstructorRole = styled.p`
  color: #666;
  margin: 0;
`;

const ViewListButton = styled.button`
  background-color: transparent;
  border: 2px solid #800000;
  color: #800000;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #800000;
    color: #fff;
  }
`;

const Instructors = () => {
  const instructors = [
    { name: 'John Doe', role: 'Frontend Developer', image: img1 },
    { name: 'Jane Smith', role: 'Backend Developer', image: img1 },
    { name: 'Jim Brown', role: 'Data Scientist', image: img1 },
    { name: 'Julia Green', role: 'Project Manager', image: img1 },
    { name: 'Jack White', role: 'UI/UX Designer', image: img1 },
    { name: 'Jill Black', role: 'DevOps Engineer', image: img1 },
    { name: 'Paul White', role: 'UI/UX Designer', image: img1 },
    { name: 'Peter Black', role: 'DevOps Engineer', image: img1 },
  ];

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <TitleLabelWrapper>
            <TitleIndicator />
            <TitleLabel>Instructor</TitleLabel>
          </TitleLabelWrapper>
          <div>
            <Title>Expert Teachers</Title>
            <Subtitle>
            Lorem ipsum dolor sit amet consectetur. <br />
            Mauris donec pharetra nec facilisi leo nec quis lectus.
            </Subtitle>

          </div>
        </TitleWrapper>
        <ViewListButton>Voir la liste</ViewListButton>
      </Header>
      <InstructorsGrid>
        {instructors.map((instructor, index) => (
          <InstructorCard key={index}>
            <InstructorImage
              src={instructor.image}
              alt={`${instructor.name}`}
            />
            <InstructorName>{instructor.name}</InstructorName>
            <InstructorRole>{instructor.role}</InstructorRole>
          </InstructorCard>
        ))}
      </InstructorsGrid>
    </Container>
  );
};

export default Instructors;
