import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import CourseCard from './CourseCard';
import { FaChevronDown, FaChevronUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import courseImg1 from '../../assets/img/courses/1.jpg';

import { getAllCourseForAllUser } from 'services/course';

const PageContainer = styled.div`
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const HeroImageContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

const HeroImage = styled.div`
  width: 100%;
  height: 400px;
  background-image: url('https://images.unsplash.com/photo-1507537297725-24a1c029d3ca');
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
`;

const TitleSection = styled.div`
  margin-bottom: 10px;
  text-align:center;

`;

const Title = styled.h1`
  font-size: 2em;
  color: #333;
  margin: 0;
`;

const SearchSection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;

  input {
    width: 100%;
    padding: 15px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 50px 0 0 50px;
    border-right: none;
    outline: none;
  }
  button {
    padding: 15px 30px;
    font-size: 1em;
    border: none;
    border-radius: 0 50px 50px 0;
    background-color: #AB1827;
    color: white;
    cursor: pointer;
    outline: none;
  }
`;

const FilterSection = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Filters = styled.div`
  min-width: 250px;
  padding-right: 20px;
  border-right: 1px solid #ddd;
`;

const FilterGroup = styled.div`
  margin-bottom: 20px;

  h4 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .toggle-icon {
      font-size: 1em;
    }
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-size: 0.9em;
    color: #555;
    display: flex;
    align-items: center;

    input {
      margin-right: 10px;
    }

    .rating-stars {
      color: #f39c12;
      margin-right: 5px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
  }
`;

const CoursesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding-left: 20px;
  align-items: start;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1em;
  color: #333;

  .pagination-info {
    margin-right: 15px;
  }

  .page-numbers, .arrow-buttons {
    display: flex;
    align-items: center;
  }

  button {
    padding: 10px 15px;
    margin: 0 5px;
    border: 1px solid #ddd;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #f0f0f0;
    }
  }

  .current-page {
    background: #fff;
    color:#AB1827;
    border: 2px solid #d9534f;
  }

  .arrow-buttons {
    margin-left: 10px;
  }

  .arrow-button {
    background: #333;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10 15px;
    &:hover {
      background: #555;
    }
  }
`;

const coursesData = [
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
  {
    title: "Fitness Development Strategy Buildup Laoreet",
    author: "Gustave ALI COUDI",
    image: courseImg1,
    price: "45,99 €",
    participants: 245,
    rating: 50,
    category: "Web developer"
  },
];

function CourseCatalog() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalElement, setTotalElement] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');




  const [expanded, setExpanded] = useState({
    notes: true,
    skillLevel: true,
    priceLevel: true,
  });

  const toggleExpand = (section) => {
    setExpanded(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  useEffect(() => {
    const data = {
      page,
      per_page: 15,
      search
    };
    getAllCourseForAllUser(data)
      .then(response => {
        setCourses(response.data.data.data);
        setTotalElement(response.data.data.total);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [page, search]);

  console.log(courses)
  return (
    <PageContainer>
      <HeroImageContainer>
        <HeroImage />
      </HeroImageContainer>
      <TitleSection>
        <Title>Tous nos cours</Title>
      </TitleSection>
      <SearchSection>
        <SearchBar>
          <input type="text" placeholder="Ex. Web developer" />
          <button>Rechercher</button>
        </SearchBar>
      </SearchSection>
      <FilterSection>
        <Filters>
          <FilterGroup>
            <h4 onClick={() => toggleExpand('notes')}>
              Notes
              <span className="toggle-icon">
                {expanded.notes ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </h4>
            {expanded.notes && (
              <>
                <label>
                  <input type="radio" name="rating" />
                  <span className="rating-stars">★★★★★</span>
                  <span>A partir de 4,5 (1500)</span>
                </label>
                <label>
                  <input type="radio" name="rating" />
                  <span className="rating-stars">★★★★☆</span>
                  <span>A partir de 4,0 (1000)</span>
                </label>
                <label>
                  <input type="radio" name="rating" />
                  <span className="rating-stars">★★★☆☆</span>
                  <span>A partir de 3,5 (500)</span>
                </label>
                <label>
                  <input type="radio" name="rating" />
                  <span className="rating-stars">★★☆☆☆</span>
                  <span>A partir de 3,0 (200)</span>
                </label>
              </>
            )}
          </FilterGroup>
          <FilterGroup>
            <h4 onClick={() => toggleExpand('skillLevel')}>
              Skill Level
              <span className="toggle-icon">
                {expanded.skillLevel ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </h4>
            {expanded.skillLevel && (
              <>
                <label><input type="radio" name="level" /> Beginner</label>
                <label><input type="radio" name="level" /> Intermediate</label>
                <label><input type="radio" name="level" /> Expert</label>
              </>
            )}
          </FilterGroup>
          <FilterGroup>
            <h4 onClick={() => toggleExpand('priceLevel')}>
              Price Level
              <span className="toggle-icon">
                {expanded.priceLevel ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </h4>
            {expanded.priceLevel && (
              <>
                <label><input type="radio" name="price" /> Free (14)</label>
                <label><input type="radio" name="price" /> Paid (26)</label>
              </>
            )}
          </FilterGroup>
        </Filters>
        <CoursesGrid>
          {courses.map((course, index) => (
            <CourseCard key={index} course={course} />
          ))}
        </CoursesGrid>
      </FilterSection>
      <Pagination>
        <span className="pagination-info">Page 1 of 30</span>
        <div className="page-numbers">
          <button>1</button>
          <button>2</button>
          <button className="current-page">3</button>
          <button>4</button>
          <button>5</button>
          <button>6</button>
        </div>
        <div className="arrow-buttons">
          <button className="arrow-button"><FaChevronLeft /></button>
          <button className="arrow-button"><FaChevronRight /></button>
        </div>
      </Pagination>
    </PageContainer>
  );
}

export default CourseCatalog;
