import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaUserFriends, FaStar, FaStarHalfAlt } from 'react-icons/fa';

const CardContainer = styled.div`
  overflow: hidden;
  background-color: #fff;
`;

const CourseImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
`;

const CourseInfo = styled.div`
  padding: 16px 0px;
`;

const PriceAndCategory = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const PriceTag = styled.div`
  background-color: #AB1827;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9em;
  margin-right: 10px;
`;

const CourseCategory = styled.span`
  font-size: 0.9em;
  color: #888;
`;

const CourseTitle = styled.h3`
  font-size: 1.2em;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #333;
`;

const CourseAuthor = styled.p`
  font-size: 0.9em;
  color: #777;
  margin: 0 0 10px 0;
`;

const CourseDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: left;
  font-size: 0.9em;
  color: #555;
`;

const Participants = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;

  svg {
    margin-right: 5px;
  }
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #f39c12;

  svg {
    margin-right: 2px;
  }
`;

function CourseCard({ course }) {
  // const fullStars = Math.floor(course.rating / 10); cequi etait la entre temps 
  const fullStars = Math.floor(20/ 10);
  // const hasHalfStar = course.rating % 10 >= 5; ce qui etais la entre temps
  const hasHalfStar = 20% 10 >= 5;

  console.log(course)

  return (
    <CardContainer>
      <Link to="/course/course-single">
        <CourseImage src={course.cover} alt={course.title} />
      </Link>
      <CourseInfo>
        <PriceAndCategory>
          <PriceTag>{course.price} {course.devise}</PriceTag>
          <CourseCategory>{course.category.name}</CourseCategory>
        </PriceAndCategory>
        <Link to="/course/course-single">
          <CourseTitle>{course.title}</CourseTitle>
        </Link>
        <CourseAuthor>{course.user.full_name}</CourseAuthor>
        <CourseDetails>
          <Participants>
            {/* <FaUserFriends /> {course.participants} */}
            <FaUserFriends /> {10}
          </Participants>
          <Rating>
            {Array(fullStars).fill().map((_, i) => <FaStar key={i} />)}
            {hasHalfStar && <FaStarHalfAlt />}
            {/* <span>{course.rating}</span> */}
            <span>{5}</span>
          </Rating>
        </CourseDetails>
      </CourseInfo>
    </CardContainer>
  );
}

export default CourseCard;
