import React from 'react';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/img/cta/home1.jpg';
import SectionTitle from '../../components/Common/SectionTitle';

const CTA = () => {
  return (
    <div style={{ position: 'relative', backgroundColor: 'rgba(247, 232, 233, 0.4)', display: 'flex', justifyContent: 'center' }} id="rs-about" className="rs-about style9 pt-100 pb-100 md-pt-70 md-pb-80">
      <div className="container" style={{ maxWidth: '75%', margin: '0 auto' }}>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 md-mb-40">
            <div className="img-part">
              <img
                src={aboutImg}
                alt='Image'
              />
            </div>
          </div>
          <div className="col-lg-6 pl-100 md-pl-14 col-md-12">
            <div className="content">
              <SectionTitle
                sectionClass="sec-title"
                subtitleClass="sub-title primary"
                subtitle={<div>ADMISSION</div>}
                titleClass="sl-title mb-20 md-mb-10"
                title="Lorem ipsum dolor sit amet consectetur."
              />
              <p className="desc mb-50">
                We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of your moment, so blinded by desire those who fail in their duty through weakness. These cases are perfectly simple and easy every pleasure is to be welcomed and every pain avoided.
              </p>
            </div>
            <div className="btn-part">
              <Link style={{backgroundColor:"#79111C",borderRadius: "78px",width: "130px",height: "55px",padding: "16px 24px",fontFamily: "Inter",fontStyle: "normal",fontWeight: "600",fontSize: "16px",lineHeight: "145%",boxSizing: "border-box", color:"white"}} className="" to="/about">Apply now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTA;
