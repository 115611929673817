import Logo from "../../utils/Logo";
import logo from "../../assets/logo/logo.jpeg";

import LoginForm from '../../components/auth/Auths/LoginForm';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

function Login() {
    return (
        <div className="grid  md:grid-cols-2 ">
            <div className="hidden md:block bg-blue-50 min-h-screen">

            </div>

            <div className="bg-white-500 min-h-screen">
                <div className="flex justify-center py-2">
                    <Logo classname={"h-10"} logo={logo}/>
                </div>
                <div className="flex flex-col items-center py-2">
                    <h1 className="text-3xl font-bold mb-2">Connectez-vous</h1>
                    <p className="bg-red-red">Je n'ai pas de compte  ,<Link to="/account" className="text-red-500"> M'inscrire</Link></p>
                </div>
                <LoginForm />
            </div>
        </div>
    );
}

export default Login;