import Logo from "../../utils/Logo";
import logo from '../../assets/logo/logo.jpeg';
import RegisterForm from "../../components/auth/Auths/RegisterForm";
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

function Register() {
    return (
        <div className="grid  md:grid-cols-2 ">
            <div className="hidden md:block bg-blue-50 min-h-screen">

            </div>

            <div className="bg-white-500 min-h-screen">
                <div className="flex justify-center py-2">
                    <Logo classname={"h-10"}  logo={logo}/>
                </div>
                <div className="flex flex-col items-center py-2">
                    <h1 className="text-3xl font-bold mb-2">Inscrivez-vous</h1>
                    <p className="bg-red-red text-center">J'ai deja un compte  <Link to="/login" className="text-red-500"> Me connecter</Link></p>
                </div>

                <RegisterForm />
            </div>
        </div>
    );
}

export default Register;