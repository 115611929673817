import { Link } from 'react-router-dom';
function Authorisation() {
    return ( 

        <div id="rs-page-error" className="rs-page-error">
        <div className="error-text">
                <h2 className="error-title">403</h2>
                <h3 className="error-message">Oups ! Accès refusé (403)</h3>
                <p className="error-desc">Désolé, vous n'êtes pas autorisé à accéder à cette page.</p>
                <Link className="readon orange-btn" to="/" title="HOME">Vous pouvez retourner à la page d'accueil en cliquant ici</Link>
            </div>
        </div>
     );
}

export default Authorisation;