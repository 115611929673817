import { useState, useEffect } from 'react'
import { FaEye } from "react-icons/fa";
import NotificationService from '../../../services/notification';
import { CiLock } from "react-icons/ci";
import { Field, Input, Label, Button } from '@headlessui/react'
import { resetPassword } from '../../../services/auth';
import { useSearchParams, useNavigate } from 'react-router-dom';

function RessetPassword() {
    const [password, setPassword] = useState('');
    //const [token,setToken] = useSearchParams();
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [token, setToken] = useState(null);
    const [searchParams] = useSearchParams();

   
    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            console.log('Token:', token);
            setToken(token);
            // Vous pouvez utiliser le token ici
            // Par exemple, vous pouvez stocker le token dans un état local ou l'envoyer à une API
        } else {
            console.error('Token not found in URL parameters');
            // Gérer le cas où le token n'est pas trouvé, par exemple, rediriger vers une page d'erreur
            navigate('/error'); // Remplacez '/error' par votre route d'erreur réelle
        }
    }, [searchParams, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (password !== confirmPassword) {
                NotificationService.error("Les mots de passe ne correspondent pas");
                return;
            }
            const data = {
                password: password,
                reset_token: token
            };
            await resetPassword(data).then(response => {
                if (response.data.success) {
                    NotificationService.success("Mot de passe réinitialisé avec succès");
                    navigate('/login');
                } else {
                    NotificationService.error(response.data.message);
                }
            });
        } catch (error) {
            NotificationService.error("Une erreur s‘est produite. Veuillez réessayer");
        }

    }
    return (
        <div className="max-w-[600px] mx-auto flex flex-col items-center  px-8 ">
            <Field className={"w-full mb-10"}>
                <Label>Mot de passe *</Label>
                <div className='relative w-full mt-2'>
                    <CiLock size={25} className='absolute top-4 left-6' />
                    <Button >
                        <FaEye size={25} className='absolute top-4 right-6' onClick={() => setShowPassword(!showPassword)} />
                    </Button>
                    <Input style={{ paddingLeft: '3rem' }} className={"border data-[focus]:shadow  border-gray-500 py-3 pr-4 rounded-full w-full pl-16"} name="password" type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
            </Field>

            <Field className={"w-full mb-10"}>
                <Label>Confirmaion de mot de passe *</Label>
                <div className='relative w-full mt-2'>
                    <CiLock size={25} className='absolute top-4 left-6' />
                    <Button >
                        <FaEye size={25} className='absolute top-4 right-6' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                    </Button>
                    <Input style={{ paddingLeft: '3rem' }} className={"border data-[focus]:shadow  border-gray-500 py-3 pr-4 rounded-full w-full pl-16"} name="confirm" type={showConfirmPassword ? 'text' : 'password'} onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                </div>
            </Field>

            <div className="w-full flex flex-1 ">
                <Button className="rounded-full bg-red-600 py-3 px-4 text-sm text-white data-[hover]:bg-red-500 data-[active]:bg-red-700  w-full" onClick={handleSubmit}>
                    Envoyer
                </Button>
            </div>



        </div>
    );
}

export default RessetPassword;