import React from 'react';
import styled from 'styled-components';
import SectionTitle from 'components/Common/SectionTitle';
import Hero from './HeroSection';
import About from './About';
import Header from 'components/Layout/Header/Header';
import Footer from 'components/Layout/Footer/Footer';
import OffWrap from 'components/Layout/Header/OffWrap';
import SearchModal from 'components/Layout/Header/SearchModal';
import CTA from 'pages/home-2/CtaSection';
import Instructors from './Instructors';

// Image
import Logo from 'assets/img/logo/dark-logo.png';
import footerLogo from 'assets/img/logo/lite-logo.png';

const AboutPage = () => {
  return (

        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='about'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
            />

            <Hero />
            <About />
            <CTA />
            <Instructors />

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            <SearchModal />
        </React.Fragment>

  );
};

export default AboutPage;
