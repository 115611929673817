import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SectionTitle from '../../components/Common/SectionTitle';
import CategoriesSingleThree from '../../components/Categories/CategoriesSingleThree';


import icon1 from '../../assets/img/categories/icons/1.png';
import icon2 from '../../assets/img/categories/icons/2.png';
import icon3 from '../../assets/img/categories/icons/3.png';
import icon4 from '../../assets/img/categories/icons/4.png';
import icon5 from '../../assets/img/categories/icons/5.png';
import icon6 from '../../assets/img/categories/icons/6.png';
import icon7 from '../../assets/img/categories/icons/7.png';
import icon8 from '../../assets/img/categories/icons/8.png';

const CategoriesContainer = styled.div`
    position: relative;
    background-color: rgba(247, 232, 233, 0.4);
    display: flex;
    justify-content: center;
    padding-top: 94px;
    padding-bottom: 70px;

    @media (max-width: 768px) {
        padding-top: 64px;
        padding-bottom: 40px;
    }
`;

const CategoriesContent = styled.div`
    max-width: 75%;
    margin: 0 auto;
`;

const CategoriesRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const CategoriesButton = styled(Link)`
    background-color: #FFFFFF;
    border: 1.5px solid #9C1623;
    border-radius: 37px;
    width: 205px;
    height: 36px;
    padding: 8px 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 145%;
    box-sizing: border-box;
    color: #ab1927;
    text-align: center;
    white-space: nowrap;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        padding: 12px 20px;
        font-size: 14px;
    }
`;

const Categories = () => {
    return (
        <CategoriesContainer className="rs-subject style1 pt-94 pb-70 md-pt-64 md-pb-40">
            <CategoriesContent className="container">
                <CategoriesRow className="row align-items-center mb-50 md-mb-30">
                    <div className="col-md-6 sm-mb-30">
                        <SectionTitle
                            sectionClass="sec-title"
                            subtitleClass="sub-title primary"
                            subtitle={<div>CATEGORIES DE COURS</div>}
                            titleClass="title mb-0"
                            title={<div><span></span>Nos meilleures catégories</div>}
                            descClass="desc big"
                            description={<div style={{ fontFamily: "'MetropolisMedium', sans-serif", fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "145%" }}>Prêt à commencer votre voyage éducatif ? <br/> Explorez nos cours dès maintenant !<br /></div>}
                        />
                    </div>
                    <div className="col-md-6 text-right sm-text-left">
                        <CategoriesButton to="/course-categories">
                            Voir toutes les catégories
                        </CategoriesButton>
                    </div>
                </CategoriesRow>

                <div className="row">
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CategoriesSingleThree
                            categoriesClass="subject-wrap bgc5"
                            title="Environnement et Développement Durable"
                            courseQuantity="10"
                            iconImg={icon1}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CategoriesSingleThree
                            categoriesClass="subject-wrap bgc5"
                            title="Développement Personnel et Professionnel"
                            courseQuantity="10"
                            iconImg={icon2}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CategoriesSingleThree
                            categoriesClass="subject-wrap bgc5"
                            title="Langues et Communication Internationale"
                            courseQuantity="10"
                            iconImg={icon3}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CategoriesSingleThree
                            categoriesClass="subject-wrap bgc5"
                            title="Sciences des Données et Analytiques"
                            courseQuantity="10"
                            iconImg={icon4}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CategoriesSingleThree
                            categoriesClass="subject-wrap bgc5"
                            title="Santé et Bien-être"
                            courseQuantity="10"
                            iconImg={icon5}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CategoriesSingleThree
                            categoriesClass="subject-wrap bgc5"
                            title="Design et Créativité"
                            courseQuantity="10"
                            iconImg={icon6}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CategoriesSingleThree
                            categoriesClass="subject-wrap bgc5"
                            title="General Education"
                            courseQuantity="10"
                            iconImg={icon7}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 mb-30">
                        <CategoriesSingleThree
                            categoriesClass="subject-wrap bgc5"
                            title="General Education"
                            courseQuantity="10"
                            iconImg={icon8}
                        />
                    </div>
                </div>
            </CategoriesContent>
        </CategoriesContainer>
    );
}

export default Categories;
