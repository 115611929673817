import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from "../../../utils/Logo";
import logo from "../../../assets/logo/logo.jpeg";
import { useAuth } from 'contexts/AuthProvider';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const ProfilePicture = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileName = styled.span`
  font-weight: bold;
`;

const ProfileEmail = styled.span`
  font-size: 0.9em;
  color: gray;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const DropdownItem = styled.button`
  padding: 10px 20px;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Header = () => {
  const { user, logOut } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <HeaderContainer>
      <Link to="/">
        <Logo classname={"h-10"} logo={logo} />
      </Link>
      <ProfileSection>
        <ProfileInfo>
          <ProfileName>{user?.full_name}</ProfileName>
          <ProfileEmail>{user?.email}</ProfileEmail>
        </ProfileInfo>
        <ProfilePicture 
          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" 
          alt="Profile" 
          onClick={toggleDropdown} 
        />
        <DropdownMenu show={dropdownOpen}>
          <DropdownItem onClick={logOut}>Déconnexion</DropdownItem>
        </DropdownMenu>
      </ProfileSection>
    </HeaderContainer>
  );
};

export default Header;
