import React, { useState } from "react";
import { Link } from 'react-router-dom';

import galleryLogo1 from '../../../assets/img/gallery/1.jpg';
import galleryLogo2 from '../../../assets/img/gallery/2.jpg';
import galleryLogo3 from '../../../assets/img/gallery/3.jpg';
import galleryLogo4 from '../../../assets/img/gallery/4.jpg';
import galleryLogo5 from '../../../assets/img/gallery/5.jpg';
import galleryLogo6 from '../../../assets/img/gallery/6.jpg';
import mapImg from '../../../assets/img/map.jpg';

const photos = [
    galleryLogo1,
    galleryLogo2,
    galleryLogo3,
    galleryLogo4,
    galleryLogo5,
    galleryLogo6
];

const CanvasMenu = (props) => {
    const { canvasClass, canvasLogo } = props;

    const canvasMenuRemove = () => {
        document.body.classList.remove('nav-expanded');
    };

    const [currentImage, setCurrentImage] = useState(null);

    const openLightbox = (index) => {
        setCurrentImage(index);
    };

    const closeLightbox = () => {
        setCurrentImage(null);
    };

    return (
        <React.Fragment>
            <nav className={canvasClass}>
                <div className="close-btn">
                    <div onClick={canvasMenuRemove} id="nav-close">
                        <div className="line">
                            <span className="line1"></span><span className="line2"></span>
                        </div>
                    </div>
                </div>
                <div className="canvas-logo">
                    <Link to="/"><img src={canvasLogo} alt="logo" /></Link>
                </div>
                <div className="offcanvas-text">
                    <p>We denounce with righteous indige nationality and dislike men who are so beguiled and demo  by the charms of pleasure of the moment data com so blinded by desire.</p>
                </div>
                <div className="offcanvas-gallery">
                    <ul>
                        {photos.map((photo, index) => (
                            <li key={index}>
                                <img src={photo} alt={`Image ${index}`} onClick={() => openLightbox(index)} />
                            </li>
                        ))}
                    </ul>
                    {currentImage !== null && (
                        <div className="modal">
                            <div className="modal-content">
                                <span className="close" onClick={closeLightbox}>&times;</span>
                                <img src={photos[currentImage]} alt="modal" />
                            </div>
                        </div>
                    )}
                </div>
                <div className="map-img">
                    <img src={mapImg} alt="" />
                </div>
                <div className="canvas-contact">
                    <ul className="social">
                        <li>
                            <a href="#"><i className="fa fa-facebook-f"></i></a>
                        </li>
                        <li>
                            <a href="#"><i className="fa fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#"><i className="fa fa-pinterest"></i></a>
                        </li>
                        <li>
                            <a href="#"><i className="fa fa-instagram"></i></a>
                        </li>
                    </ul>
                </div>
            </nav>
        </React.Fragment>
    );
}

export default CanvasMenu;
