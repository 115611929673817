import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  width: 80%;
  margin: 4rem auto;
  background-color: #ffffff;
`;

const AboutContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const AboutTextContainer = styled.div`
  flex: 1;
  max-width: 45%;
`;

const TitleMarker = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const MarkerLine = styled.div`
  width: 50px;
  height: 2px;
  background-color: #800000;
  margin-right: 1rem;
`;

const SectionLabel = styled.span`
  color: #800000;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const AboutTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  color: #000;
`;

const AboutSubtitle = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin-top: 0.5rem;
`;

const DetailedText = styled.div`
  flex: 1;
  max-width: 45%;
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
`;

const StatsContainer = styled.div`
  display: flex;
  max-width: 45%;
  gap: 100px;
`;

const Stat = styled.div`
  text-align: center;
`;

const StatNumber = styled.div`
  font-size: 3rem;
  font-weight: bold;
  color: #000;
`;

const StatLabel = styled.div`
  font-size: 1rem;
  color: #666;
`;

const About = () => (
  <AboutSection>
    <AboutContent>
      <AboutTextContainer>
        <TitleMarker>
          <MarkerLine />
          <SectionLabel>About Educavo</SectionLabel>
        </TitleMarker>
        <AboutTitle>Welcome to The Educavo Online Learning</AboutTitle>
        <AboutSubtitle>
          Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus.
        </AboutSubtitle>
      </AboutTextContainer>
      <DetailedText>
        <p>Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis. Vitae rhoncus cursus aenean aliquam id morbi fermentum non eget.</p>
        <p>Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis. Vitae rhoncus cursus aenean aliquam id morbi fermentum non eget.</p>
        <p>Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis. Vitae rhoncus cursus aenean aliquam id morbi fermentum non eget.</p>
        <StatsContainer>
      <Stat>
        <StatNumber>2k+</StatNumber>
        <StatLabel>Students</StatLabel>
      </Stat>
      <Stat>
        <StatNumber>50</StatNumber>
        <StatLabel>Students</StatLabel>
      </Stat>
      <Stat>
        <StatNumber>95%</StatNumber>
        <StatLabel>Students</StatLabel>
      </Stat>
    </StatsContainer>
      </DetailedText>
    </AboutContent>

  </AboutSection>
);

export default About;
