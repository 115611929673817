// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL , NGROCK_URL} from 'services';
import { Outlet, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    const loginAction = async () => {
      const token =  localStorage.getItem("token");
      console.log(token);
      if (!token) {
        setLoading(false);
       navigate("/login")
      }
      axios.defaults.headers["Authorization"] = `Bearer ${token}`
      try {
        const response = await axios.post(
          `${API_BASE_URL}/me`,
          {}, // Corps de la requête, vide dans ce cas
        );
        const res = response.data;
        if (res.success) {
          setUser(res.data.user);

        } else {
          setUser(null);
          setToken("");
          localStorage.removeItem("token");
          navigate("/login");
        }
      } catch (err) {
        console.log(err);
        setUser(null);
        setToken("");
        localStorage.removeItem("token");
      } finally {
        setLoading(false);
      }
    };
   
    loginAction();

  }, []);

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    // remove everything from local storage
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, logOut, setUser, loading }}>
      <Outlet />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
