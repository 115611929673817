import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SectionTitle from '../../components/Common/SectionTitle';

const AboutContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 100px;

    @media (max-width: 768px) {
        padding-top: 70px;
    }
`;

const AboutContent = styled.div`
    max-width: 75%;
    margin: 0 auto;
`;

const AboutRow = styled.div`
    display: flex;
    align-items: flex-start;
    padding-top: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const AboutColumn = styled.div`
    flex: 1;

    &:first-child {
        margin-bottom: 30px;
    }
`;

const Button = styled(Link)`
    background-color: #FFFFFF;
    border: 1.5px solid #9C1623;
    border-radius: 37px;
    width: 148px;
    height: 55px;
    padding: 16px 24px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 145%;
    box-sizing: border-box;
    color: #ab1927;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap; /* Ajout de cette ligne */

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        padding: 12px 20px;
        font-size: 14px;
    }
`;

const About = () => {
    return (
        <AboutContainer id="rs-about" className="rs-about style3">
            <AboutContent className="container">
                <AboutRow className="row y-middle">
                    <AboutColumn className="col-lg-4 md-mb-30">
                        <div className="about-intro">
                            <SectionTitle 
                                sectionClass="sec-title"
                                subtitleClass="sub-title primary"
                                subtitle={<div>A PROPOS DE NOUS</div>}
                                titleClass="title mb-20"
                                title="Notre mission"
                                descClass="desc big"
                                description={
                                    <div 
                                        style={{
                                            fontFamily: "'MetropolisMedium', sans-serif",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            lineHeight: "145%"
                                        }}
                                    >
                                        Connecter les esprits, élargir les horizons.
                                        <br />
                                        Découvrez comment notre école de formation 
                                        <br />
                                        à distance repousse les limites de l'éducation traditionnelle.
                                    </div>
                                }
                            />
                        </div>
                    </AboutColumn>
                    <AboutColumn className="col-lg-8 pl-82 md-pl-14">
                        Une équipe dévouée à faire de l'éducation une expérience sans frontières. Explorez notre histoire, nos valeurs et notre engagement envers l'excellence éducative...
                        Une équipe dévouée à faire de l'éducation une expérience sans frontières.
                        <br /><br />
                        Une équipe dévouée à faire de l'éducation une expérience sans frontières. Explorez notre histoire, nos valeurs et notre engagement envers l'excellence éducative...
                        Une équipe dévouée à faire de l'éducation une expérience sans frontières. 
                        <br /><br />
                        Une équipe dévouée à faire de l'éducation une expérience sans frontières. Explorez notre histoire, nos valeurs et notre engagement envers l'excellence éducative...
                        Une équipe dévouée à faire de l'éducation une expérience sans frontières. 
                        <br /><br />
                        <div className="btn-part mb-100">
                            <Button to="/about">
                                En savoir plus
                            </Button>
                        </div>
                    </AboutColumn>
                </AboutRow>
            </AboutContent>
        </AboutContainer>
    );
}

export default About;
