import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StepContainer = styled.div`
  padding: 20px;
  width: 100%;
`;

const StepHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
`;

const StepThree = ({ data, setData }) => {
  const [courseTitle, setCourseTitle] = useState(data.description.courseTitle || '');
  const [description, setDescription] = useState(data.description.description || '');
  const [keywords, setKeywords] = useState(data.description.keywords || '');

  useEffect(() => {
    setData({
      ...data,
      description: {
        courseTitle,
        description,
        keywords
      }
    });
    
  }, [courseTitle, description, keywords]);

  return (
    <StepContainer>
      <StepHeader>Description de votre cours</StepHeader>
      <p>Phasellus enim magna, varius et commodo ut, ultricies vitae velit.</p>

      <label>Titre du cours</label>
      <Input
        type="text"
        value={courseTitle}
        onChange={(e) => setCourseTitle(e.target.value)}
        placeholder="Titre du cours"
      />

      <label>Description</label>
      <TextArea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        rows="5"
      />

      <label>Mots clés</label>
      <TextArea
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        placeholder="Mots clés"
        rows="5"
      />
    </StepContainer>
  );
};

export default StepThree;
