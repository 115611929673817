import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';

import footerLogo1 from '../../../assets/img/logo/logo.png';
//import postImg1 from '../../../assets/img/blog/post1.jpg';
//import postImg2 from '../../../assets/img/blog/post2.jpg';

const Footer = (props) => {
    const { footerLogo, footerClass, footerTopClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className={`footer-top ${footerTopClass}`}>
                <div className="container" style={{ maxWidth: '75%', margin: '0 auto' }}>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <div className="footer-logo mb-30">
                                <Link to="/" as="/">
                                    <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" />
                                </Link>
                            </div>
                            <div className="pr-60 md-pr-14">
                                <p style={{fontStyle:"normal", fontWeight:"400", fontSize:"16px", color:"#B7AFAF", lineHeight:"23.2px"}}>Let us unleash our creativity and expertise to create designs that deliver extraordinary results.</p>
                                <span className='pc' style={{fontSize: "14px"}}>© 2024 Akambi Consulting All rights reserved.</span>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h3 style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#CDC4C4"}} className="">Rayna Products</h3>
                            <ul className="address-widget">
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Mass Payments</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Debit Card</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Rayna for Startups</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Rayna for Developers</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Rayna for No-code</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Assets</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h3 style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#CDC4C4"}} className="">Industry Solutions</h3>
                            <ul className="address-widget">
                            <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Financial Services</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Fashion & Beauty</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Agriculture</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Manufacturing</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Education</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-12 col-sm-12  md-mb-50">
                            <h3 style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#CDC4C4", }} className="">Support</h3>
                            <ul className="address-widget">
                            <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Help centre</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">FAQ</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Contact</Link></li>
                                <li><Link style={{fontFamily:"Inter",fontStyle:"normal", fontWeight:"500", fontSize:"16px", color:"#645D5D"}} to="/course">Press</Link></li>
                            </ul>
                           
                        </div>
                        
                    </div>
                    
                </div>
            
            </div>
          
        </footer>
    );
}

export default Footer;