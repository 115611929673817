import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu } = props;

    const location = useLocation();

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ' '}><Link to="/">Accueil</Link></li>
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}><Link to="/about">A propos</Link></li>
            <li className={parentMenu === 'course' ? 'current-menu-item' : ''}><Link to="/course">Cours</Link></li>
            <li className={parentMenu === 'evenement' ? 'current-menu-item' : ''}><Link to="/event">Evénements</Link></li>
            <li className={parentMenu === 'blog' ? 'current-menu-item' : ''}><Link to="/blog">Articles</Link></li>
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}><Link to="/contact">Contact</Link></li>
        </React.Fragment>
    );
}

export default MenuItems;