import axios from "axios";
import { API_BASE_URL ,NGROCK_URL} from ".";



export const login = (data) => {
    return axios.post(`${API_BASE_URL}/login`, data );
    };


export const register = (data) => {
    return axios.post(`${API_BASE_URL}/register`, data );
    };


export const sendPasswordResetEmail = (data) => {
    return axios.post(`${API_BASE_URL}/forgot-password`, data );
    }

export const resetPassword = (data) => {
    return axios.post(`${API_BASE_URL}/reset-password`, data );
    }