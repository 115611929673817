import Logo from "../../utils/Logo";
import logo from '../../assets/logo/logo.jpeg';
import 'tailwindcss/tailwind.css';
import RessetPassword from '../../components/auth/Auths/RessetPassword';


function RessetPasswordPage() {
    return ( 
        <div className="grid  md:grid-cols-2 ">
        <div className="hidden md:block bg-gray-500 min-h-screen">

        </div>

        <div className="bg-white-500 min-h-screen">
            <div className="flex justify-center py-10 mt-[200px]">
                <Logo classname={"h-10"} logo={logo}/>
            </div>
            <RessetPassword />
        </div>
    </div>
     );
}

export default RessetPasswordPage;