import Authorisation from "components/Error/Authorisation";
import React from 'react';

function Access() {
    return (
        <React.Fragment>
            <Authorisation />
        </React.Fragment>
    );
}

export default Access;