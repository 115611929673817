import React from 'react';
import { Helmet } from 'react-helmet';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import ContactMain from './ContactMain';

// Image
import favIcon from '../../assets/img/fav-orange.png';
import Logo from '../../assets/img/logo/dark-logo.png';
import stickyLogo from '../../assets/img/logo/logo-dark.png';
import mobileLogo from '../../assets/img/logo/logo-dark.png';
import canvasLogo from '../../assets/img/logo/logo-dark.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';


const Contact = () => {
    return (
        <React.Fragment>
            <OffWrap />
            {/* Header */}
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <Header
                parentMenu='contact'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                mobileNormalLogo={mobileLogo}
                CanvasLogo={canvasLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                emailAddress='support@akambi-consulting.com'
                Location='Adresse AKAMBI '
            />
            {/* Header */}

            {/* ContactMain Section Start */}
            <ContactMain />
            {/* ContactMain Section End */}

            {/* Footer */}
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}


export default Contact;