import React from 'react';
import { Helmet } from 'react-helmet'
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Header from '../../components/Layout/Header/Header';
import HomeFiveMain from './HomeFiveMain';
import Footer from '../../components/Layout/Footer/Footer';

import favIcon from '../../assets/img/fav.png';
import Logo from '../../assets/img/logo/logo-dark.png';
import stickyLogo from '../../assets/img/logo/logo-dark.png';
import mobileLogo from '../../assets/img/logo/logo-dark.png';
import canvasLogo from '../../assets/img/logo/logo-dark.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';

const HomeFive = () => {
    return (
        <React.Fragment>
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <OffWrap />
            <Header
                parentMenu='home'
                headerNormalLogo={Logo}
                headerStickyLogo={stickyLogo}
                mobileNormalLogo={mobileLogo}
                CanvasLogo={canvasLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBarClass="topbar-area home8-topbar"
                emailAddress='support@akambi-consulting.com'
                Location='Adresse AKAMBI '
            />
            <HomeFiveMain />
         
            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            <SearchModal />
        </React.Fragment>
    );
}


export default HomeFive;