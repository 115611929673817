import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import { GoogleOAuthProvider } from '@react-oauth/google';


import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_REACT_APP_GOOGLE_CLIENT_ID}>
    <App tab='Accueil' />
  </GoogleOAuthProvider>
);
reportWebVitals();