import { Link } from "react-router-dom";

function Logo(propos: any) {
  
    return (
        <div className="logo">
            <Link to="/" >
            <img className={`${propos.classname}`} src={propos.logo} alt="Logo" />
            </Link>
        </div>
    );
}

export default Logo;