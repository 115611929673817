import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Sidebar from './Sidebar';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <Header />
      <MainContainer>
        <Sidebar />
        <Content>
          {children}
        </Content>
      </MainContainer>
    </LayoutContainer>
  );
};

export default Layout;
