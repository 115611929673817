import axios from "axios";
import { API_BASE_URL, NGROCK_URL } from ".";


export const editProfile = (data) => {
    return axios.post(`${API_BASE_URL}/update`, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}

export const checkIfMailExist = (data) => {
    return axios.post(`${API_BASE_URL}/check-email`, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    });
}
