import React from 'react';
import styled from 'styled-components';
import { FaRegClock, FaCertificate, FaStar, FaTrashAlt } from 'react-icons/fa';

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif; // Assurer la cohérence de la police
`;

const CartHeader = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; // Couleur de texte ajustée
  span {
    font-size: 1.2rem;
    color: #d9534f;
  }
`;

const CartItems = styled.div`
  border-top: 2px solid #eee;
  padding-top: 20px;
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; // Alignement des items
  border-bottom: 1px solid #eee;
  padding: 10px 0;
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1; // Assurer que les items prennent tout l'espace disponible
`;

const ItemImage = styled.img`
  width: 80px; // Ajustement de la taille pour correspondre au design
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 5px; // Ajout d'un bord arrondi
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ItemTitle = styled.div`
  font-size: 1.2rem; // Taille ajustée
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
`;

const ItemAuthor = styled.div`
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 10px;
`;

const ItemAttributes = styled.div`
  display: flex;
  font-size: 0.9rem;
  color: #555;

  & > div {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 5px;
  }
`;

const ItemPrice = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #d9534f;

  button {
    background: #d9534f;
    border: 1px solid #d9534f;
    color: white;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 3px;

    &:hover {
      background: #c9302c; // Couleur de survol
    }
  }

  svg {
    margin-right: 5px;
  }
`;

const CartSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #f8d7da;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
`;

const Total = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
`;

const CheckoutButton = styled.button`
  background-color: #d9534f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;

  &:hover {
    background-color: #c9302c;
  }
`;

const RecommendedSection = styled.div`
  margin-top: 20px;
`;

const RecommendedTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
`;

function CartPage() {
  const cartItems = [
    {
      id: 1,
      title: "Spicy Quince And Cranberry Chutney",
      author: "Gustave ALI-COUDI",
      duration: "2h30m",
      certificate: true,
      rating: 4.5,
      price: 45.99,
      imageUrl: "https://via.placeholder.com/100"
    },
    {
      id: 2,
      title: "Persim, Pomegranate, And Massage Kale Salad",
      author: "Gustave ALI-COUDI",
      duration: "2h30m",
      certificate: true,
      rating: 4.5,
      price: 45.99,
      imageUrl: "https://via.placeholder.com/100"
    },
    {
      id: 3,
      title: "Spicy Quince And Cranberry Chutney",
      author: "Gustave ALI-COUDI",
      duration: "2h30m",
      certificate: true,
      rating: 4.5,
      price: 45.99,
      imageUrl: "https://via.placeholder.com/100"
    }
  ];

  const total = cartItems.reduce((sum, item) => sum + item.price, 0);

  return (
    <MainContainer>
      <CartHeader>Panier <span>{cartItems.length} cours dans le panier</span></CartHeader>
      <CartItems>
        {cartItems.map(item => (
          <CartItem key={item.id}>
            <ItemInfo>
              <ItemImage src={item.imageUrl} alt={item.title} />
              <ItemDetails>
                <ItemTitle>{item.title}</ItemTitle>
                <ItemAuthor>Un cours de {item.author}</ItemAuthor>
                <ItemAttributes>
                  <div><FaRegClock /> {item.duration}</div>
                  {item.certificate && <div><FaCertificate /> Certificat</div>}
                  <div><FaStar /> {item.rating}</div>
                </ItemAttributes>
              </ItemDetails>
            </ItemInfo>
            <ItemPrice>
              <button><FaTrashAlt /> Supprimer</button>
              {item.price.toFixed(2)} €
            </ItemPrice>
          </CartItem>
        ))}
      </CartItems>
      <CartSummary>
        <Total>Total {total.toFixed(2)} €</Total>
        <CheckoutButton>Valider le panier</CheckoutButton>
      </CartSummary>
      <RecommendedSection>
        <RecommendedTitle>Cours qui pourraient vous intéresser</RecommendedTitle>
        {/* Recommended courses would go here */}
      </RecommendedSection>
    </MainContainer>
  );
}

export default CartPage;
