import React, { useState } from 'react';
import styled from 'styled-components';
import author1 from '../../assets/img/testimonial/style3/1.png';
import author2 from '../../assets/img/testimonial/style3/2.png';
import author3 from '../../assets/img/testimonial/style3/3.png';
import author4 from '../../assets/img/testimonial/style3/4.png';
import author5 from '../../assets/img/testimonial/style3/5.png';
import SectionTitle from '../../components/Common/SectionTitle';

const testimonials = [
  {
    id: 1,
    name: 'Floyd Miles',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author1,
  },
  {
    id: 2,
    name: 'Jane Cooper',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author2,
  },
  {
    id: 3,
    name: 'Robert Fox',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author3,
  },
  {
    id: 4,
    name: 'Kristin Watson',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author4,
  },
  {
    id: 5,
    name: 'Cody Fisher',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author5,
  },

  {
    id: 6,
    name: 'Jane Cooper',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author2,
  },
  {
    id: 7,
    name: 'Robert Fox',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author3,
  },
  {
    id: 8,
    name: 'Kristin Watson',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author4,
  },
  {
    id: 9,
    name: 'Cody Fisher',
    title: 'Web developer',
    text: 'Lorem ipsum dolor sit amet consectetur. Mauris donec pharetra nec facilisi leo nec quis lectus. Nisi tortor mollis ac eget non ac maecenas risus. Interdum a semper nunc tincidunt libero condimentum quis.',
    img: author5,
  },
];


const Section = styled.section`
  text-align: left;
  padding: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  max-width: 75%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TitleWrapper = styled.div`
  text-align: left;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const Card = styled.div`
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  width: ${({ remainingCards }) =>
    remainingCards === 1 ? '100%' : remainingCards === 2 ? 'calc(50% - 20px)' : 'calc(33.33% - 20px)'};

  @media (max-width: 1024px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 20px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const NameTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
`;

const TitleText = styled.h4`
  color: grey;
  margin: 0;
  font-size: 14px;
  margin-top: 7px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 1.6;
`;

const NavButtons = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Button = styled.button`
  background-color: #fff;
  color: #c1272d;
  border: 2px solid #c1272d;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  &:hover {
    background-color: #c1272d;
    color: #fff;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const DotsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.active ? '#AB1827' : '#ddd')};
  border-radius: 50%;
  margin: 0 5px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const TestimonialCard = ({ name, title, text, img, remainingCards }) => (
  <Card remainingCards={remainingCards}>
    <CardHeader>
      <Image src={img} alt={`${name}`} />
      <NameTitleContainer>
        <Name>{name}</Name>
        <TitleText>{title}</TitleText>
      </NameTitleContainer>
    </CardHeader>
    <Text>{text}</Text>
  </Card>
);

const Testimonials = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 3;

  const handleNext = () => {
    if (currentPage < Math.ceil(testimonials.length / cardsPerPage) - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleDotClick = (pageIndex) => {
    if (pageIndex !== currentPage) {
      setCurrentPage(pageIndex);
    }
  };

  const totalPages = Math.ceil(testimonials.length / cardsPerPage);
  const startIndex = currentPage * cardsPerPage;
  const visibleTestimonials = testimonials.slice(startIndex, startIndex + cardsPerPage);
  const remainingCards = visibleTestimonials.length;

  return (
    <Section>
      <Container>
        <HeaderContainer>
          <TitleWrapper>
            <SectionTitle
              sectionClass="sec-title"
              subtitleClass="sub-title"
              subtitle={<div>TEMOIGNAGES</div>}
              titleClass="title"
              title={<div>Ce que disent <br />nos étudiants...</div>}
            />
          </TitleWrapper>
          <NavButtons>
            <Button onClick={handlePrev} disabled={currentPage === 0}>
              &#8592;
            </Button>
            <Button onClick={handleNext} disabled={currentPage === totalPages - 1}>
              &#8594;
            </Button>
          </NavButtons>
        </HeaderContainer>
        <CardsWrapper>
          {visibleTestimonials.map((testimonial) => (
            <TestimonialCard key={testimonial.id} {...testimonial} remainingCards={remainingCards} />
          ))}
        </CardsWrapper>
        <DotsContainer>
          {Array.from({ length: Math.min(3, totalPages) }).map((_, pageIndex) => (
            <Dot
              key={pageIndex}
              active={pageIndex === currentPage}
              disabled={pageIndex === totalPages - 1 && currentPage === totalPages - 1}
              onClick={() => handleDotClick(pageIndex)}
            />
          ))}
        </DotsContainer>
      </Container>
    </Section>
  );
};

export default Testimonials;
